import React from "react";
import { FormControl, Select, InputLabel, Button, Autocomplete, TextField, createFilterOptions } from '@mui/material';
import { Error, DeleteOutlineOutlined } from "@mui/icons-material";
import { helper } from '../../../assets/js/utils/Element';

import "../../../assets/scss/step1Style.scss";

const filter = createFilterOptions();

const Step1 = class extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            recipients: [
                {
                    first_name: '',
                    email: '',
                    emailOrg: '',
                    relationship: '',
                    hug_email: 0,
                    hug_email_date: null,
                    hug_view: 0,
                    hug_creator_email: 0
                }
            ],
            notes: '',
            isValidate: false,
            relationshipOptions: helper.relationshipOptions(),
            relationshipAdd: "",
            noteLimit: 300,
            noteLength: 0,
        };

        if(this.props.state !== null){
            this.state = this.props.state;
        }
    }
    cloneInputs = () => {
        this.setState({
            recipients: [
                ...this.state.recipients,
                {
                    first_name: '',
                    email: '',
                    emailOrg: '',
                    relationship: '',
                    hug_email: 0,
                    hug_email_date: null,
                    hug_view: 0,
                    hug_creator_email: 0
                }
            ]
        })
    }
    handleRecipientChange(e, key, name){
        let recipients = this.state.recipients;
        let value = e.target.value;
        if(name === "email"){
            value = value.toLowerCase();
        }
        recipients[key][name] = value;

        this.setState({
            recipients: recipients
        });
    }
    handleRelationshipChange(e, newValue, key, name){
        let recipients = this.state.recipients;
        
        let value = '';
        let newValueAdded = false;

        if (typeof newValue === 'string') {
            value = newValue;
        } else if (newValue && newValue.inputValue) {
            value = newValue.inputValue;
            newValueAdded = true;
        } else if(newValue && newValue.value) {
            value = newValue.key;
        }

        recipients[key][name] = value;

        this.setState({
            recipients: recipients
        },() => {
            if(newValueAdded){
                this.setState({
                    relationshipAdd: "",
                    recipients: recipients,
                    relationshipOptions: [
                        ...this.state.relationshipOptions,
                        {
                            key: value,
                            value: value
                        }
                    ],
                });
            }
        });
    }
    handleChange(e, name){
        let value = e.target.value;
        if(value.length > 300){
            value = value.slice(0, 300);
        }

        this.setState({
            [name]: value,
            noteLength: value.length
        });
    }
    isValid(){
        const { recipients } = this.state;
        const { editStep } = this.props;

        let isValid = true;
        recipients.map(recipient => {
            if(recipient.first_name.length <= 0){
                isValid = false;
            }
            var emailRegex = /\S+@\S+\.\S+/;
            if(editStep && recipient.emailOrg.length > 0 && (recipient.email.length <= 0 || !emailRegex.test(recipient.email))){
                isValid = false;
            }
            if(recipient.relationship.length <= 0){
                isValid = false;
            }

            return null;
        });

        this.setState({
            isValidate: true
        })

        return isValid;
    }
    isEmailValid(email){
        let isValid = true;

        var emailRegex = /\S+@\S+\.\S+/;
        if(email.length <= 0 || !emailRegex.test(email)){
            isValid = false;
        }

        return isValid;
    }
    removeRecipient(){
        let { recipients }  = this.state;
        recipients.splice(recipients.length-1, 1);

        this.setState({
            recipients: recipients
        });
    }
    handleRelationshipType(e){
        this.setState({
            relationshipAdd: e.target.value
        })
    }
    handleRelationshipAdd(e, key, name){
        e.preventDefault();

        let recipients = this.state.recipients;
        let value = this.state.relationshipAdd;

        recipients[key][name] = value;

        this.setState({
            relationshipAdd: "",
            recipients: recipients,
            relationshipOptions: [
                ...this.state.relationshipOptions,
                {
                    key: value,
                    value: value
                }
            ],
        });
    }
    render() {
        const { recipients, notes, isValidate, relationshipOptions, relationshipAdd } = this.state;
        const { editStep, isHugDeleted } = this.props;

        return (
            <div className="sd-step-one-content">
                <div className="sd-step-one-heading">
                    <h3>Who is the Hug For?</h3>
                    {
                        editStep ?
                            <>
                                <Button className="button radius-8 sd-delete-hug" onClick={() => this.props.onDeleteModal(true)}>
                                    {
                                        isHugDeleted ?
                                            <>
                                                <DeleteOutlineOutlined />Cancel Delete
                                            </>
                                        :
                                            <>
                                                <DeleteOutlineOutlined />Delete
                                            </>
                                    }
                                </Button>
                            </>
                        :
                            <></>
                    }
                </div>
                <div className="sd-step-one-form-content">
                    {
                        recipients.map((recipient, key) => (
                            <div className="sd-step-one-inputs" key={key}>
                                <div className="element-container">
                                    <input 
                                        className={isValidate && recipient.first_name.length <= 0 ? 'error' : ''}
                                        type={"text"} 
                                        placeholder="First Name*" 
                                        onChange={(e) => this.handleRecipientChange(e,key, 'first_name')}    
                                        value={recipient.first_name}
                                    />
                                    {
                                        isValidate && recipient.first_name.length <= 0 ?
                                            <Error />
                                        :
                                            <></>
                                    }
                                </div>
                                {
                                    editStep && (recipient.email.length > 0 || recipient.emailOrg.length > 0) ?
                                        <div className="element-container">
                                            <input 
                                                className={isValidate && !this.isEmailValid(recipient.email) ? 'error' : ''}
                                                type={"email"} 
                                                placeholder="Email*" 
                                                onChange={(e) => this.handleRecipientChange(e,key, 'email')}
                                                value={recipient.email}
                                            />
                                            {
                                                isValidate && !this.isEmailValid(recipient.email) ?
                                                    <Error />
                                                :
                                                    <></>
                                            }
                                        </div>
                                    :
                                        <></>
                                }
                                <div className="element-container relationship-element">
                                    <Autocomplete
                                        value={recipient.relationship}
                                        onChange={(e, newValue) => this.handleRelationshipChange(e, newValue, key, 'relationship')}
                                        filterOptions={(options, params) => {
                                            const filtered = filter(options, params);
                                            const { inputValue } = params;
                                            const isExisting = options.some((option) => inputValue === option.value);
                                            if (inputValue !== '' && !isExisting) {
                                                filtered.push({
                                                    inputValue,
                                                    value: `Add "${inputValue}"`,
                                                });
                                            }
                                    
                                            return filtered;
                                        }}
                                        openOnFocus
                                        selectOnFocus
                                        clearOnBlur
                                        handleHomeEndKeys
                                        freeSolo
                                        options={relationshipOptions}
                                        getOptionLabel={(option) => {
                                            if (typeof option === 'string') {
                                                return option;
                                            }
                                            if (option.inputValue) {
                                                return option.inputValue;
                                            }
                                            return option.value;
                                        }}
                                        renderOption={(props, option) => <li {...props}>{option.value}</li>}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Relationship *" />
                                        )}
                                    />
                                </div>
                                <div className="element-container">
                                    <FormControl>
                                        <InputLabel>Relationship *</InputLabel>
                                        <Select
                                            onChange={(e) => this.handleRecipientChange(e, key, 'relationship')}
                                            className={"sd-relationship-select "+(isValidate && recipient.relationship.length <= 0 ? 'error' : '')}
                                            MenuProps={{
                                                className: 'sd-relationship-select-menu'
                                            }}
                                            value={recipient.relationship}
                                        >
                                            { helper.renderOptions(relationshipOptions) }
                                            <div className="sd-custom-relationship-value">
                                                <form onSubmit={(e) => this.handleRelationshipAdd(e, key, 'relationship')}>
                                                    <label htmlFor="other">Other</label>
                                                    <input 
                                                        type="text" 
                                                        id="other" 
                                                        name="other" 
                                                        value={relationshipAdd}
                                                        onChange={(e) => this.handleRelationshipType(e)}
                                                        onKeyDown={(e) => {
                                                            if (e.key !== "Escape") {
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </form>
                                            </div>
                                        </Select>
                                    </FormControl>
                                    {
                                        isValidate && recipient.relationship.length <= 0 ?
                                            <Error />
                                        :
                                            <></>
                                    }
                                </div>
                            </div>
                        ))
                    }
                    <div className="sd-step-one-form-btns">
                        <Button className="button radius-8" onClick={this.cloneInputs}>
                            Add Another
                        </Button>
                        {
                            recipients.length > 1 ?
                                <Button className="button remove radius-8" onClick={() => this.removeRecipient()}>
                                    Remove Recipient
                                </Button>
                            :
                                <></>
                        }
                    </div>
                    <textarea
                        placeholder="Provide a greeting for your loved one that will be delivered along with your hug. Example: “You are my sunshine. Hope this brightens your day!”"
                        onChange={(e) => this.handleChange(e,'notes')}
                        value={notes}
                    />
                    <p>{ this.state.noteLength }/{ this.state.noteLimit }</p>
                </div>
            </div>
        );
    }
}

export default Step1;