import { Button } from "@mui/material";
import React from "react";
import heartIcon from "../../../assets/img/heart_icon.png";
import { Link as RouterLink } from "react-router-dom";
import InspirationMessageModal from "../../components/Modal/InspirationMessageModal";

import "../../../assets/scss/step5Style.scss";

const Step6 = class extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            edit: false,
            inspirationMessageModal: false
        }

        if(this.props.state !== null){
            this.state = this.props.state;
        }
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    isValid(){
        return true;
    }
    onInspirationMessageModal(status = false){
        this.setState({
            inspirationMessageModal: status
        })
    }
    render() {
        const { inspirationMessageModal } = this.state;
        return (
            <>
                <div className="sd-step-five-content">
                    <div className="sd-step5-note">
                        <img src={heartIcon} alt="heart-icon" />
                        <h1>Way to take the first step!</h1>
                        <p>Don’t keep your loved one’s waiting and come back soon to finish your hug.</p> 
                        <p className="audio-note" onClick={() => this.onInspirationMessageModal(true)}>Inspirational Hug Messages</p>
                        <RouterLink to={"/home"} className="buttonlink" onClick={() => this.onLinkClick("/home")} >
                            <Button className="button radius-8">Go To Dashboard</Button>
                        </RouterLink>
                    </div>
                </div>
                {
                    inspirationMessageModal ?
                        <InspirationMessageModal
                            open={inspirationMessageModal}
                            onClose={() => this.onInspirationMessageModal(false)} 
                        />
                    :
                        <></>                        
                }
            </>
        );
    }
}

export default Step6;