import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, Button, DialogActions } from "@mui/material";
import { Close } from "@mui/icons-material";
import LoaderComponent from '../Loader'

import "../../../assets/scss/successModalStyle.scss";

class SuccessModal extends React.Component {
    render() {
        const { saving } = this.props;

        return (
            <>
                <Dialog
                    className="sd-success-modal"
                    open={this.props.open}
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                        <p className={"modal-title"}>{ this.props.title }</p>
                    </DialogTitle>
                    <DialogContent>
                        {
                            this.props.desc !== ''?
                                <p>{this.props.desc}</p>
                            :
                                <></>
                        }
                        
                    </DialogContent>
                    <DialogActions
                        className={"modal-footer"}
                    >
                        {
                            saving ?
                                <LoaderComponent />
                            :
                                <Button className="button radius-8" onClick={() => this.props.onButtonAction()}>{this.props.buttonText}</Button>
                        }
                    </DialogActions> 
                </Dialog>
            </>
        )
    }
}

SuccessModal.defaultProps = {
    title: "",
    desc: "",
    buttonText: "Close",
    saving: false
}

export default SuccessModal;