import React, { Component } from "react";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { AddOutlined, PlayArrow, CalendarTodayOutlined, ModeEdit, Refresh } from "@mui/icons-material";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import LoaderComponent from '../../components/Loader';
import AuthApi from '../../../assets/js/utils/Auth';
import HugReminderModal from "../../components/Modal/HugReminderModal";
import EmailModal from "../../components/Modal/EmailModal";
import { InlineShareButtons } from 'sharethis-reactjs';
import TileIcon from "../../../assets/img/tile-view.png";
import ListIcon from "../../../assets/img/list-view.png";
import moment from 'moment';
import ReactTable from "react-table";
import AddRecipientModal from "../../components/Modal/AddRecipientModal";
import SuccessModal from '../../components/Modal/SuccessModal';
import Progressbar from "../../components/Progressbar";

import "../../../assets/scss/reactTableStyle.scss";
import "../../../assets/scss/userHomeStyle.scss";

class Home extends Component {
    constructor(props) {
        super(props);
        this.scrollerRef = React.createRef();

        this.state = {
            limit: 12,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            hugReminderModal: false,
            hugId: null,
            hug: null,
            emailModal: false,
            showFullNotes: [],
            addRecipientModal: false,
            emailSuccessModal: false
        };
    }
    componentDidMount(){
        const { authorized } = this.props.state;
        if(!authorized && !AuthApi.hasAccessToken()){
            const location = this.props.history.location;

            const loginRequired = "/login?return="+encodeURIComponent(location.pathname+location.search);
            this.props.history.push(loginRequired);
            return;
        }

        this.getUserHugs();

        window.addEventListener("scroll",this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    componentDidUpdate(prevProps){
        if(this.props.history.hasOwnProperty('home') && this.props.history.home){
            this.props.history.home = false;
            this.getUserHugs();
        }
        if(prevProps.state.activeTab !== this.props.state.activeTab){ 
            this.getUserHugs();
        }
    }
    handleScroll = () => {
        const { response, loading, loadingMore, page, totalPages } = this.state;
        
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const windowHeight = window.innerHeight;
        const scrollHeight = document.documentElement.scrollHeight;
        
        if (response !== null && response.length > 0 && loading === false && loadingMore === false && page < totalPages && scrollTop + windowHeight >= scrollHeight - 200) {
            this.getUserHugs(true);
        }
    }
    handleTabChange(tab){
        this.props.dispatch({
            type: 'UPDATE_STATE',state: {activeTab: tab}
        });
    }
    handleChangeLayout(type){
        this.props.dispatch({type: 'UPDATE_STATE',state: {layout: type}});
    }
    getUserHugs(viewMore = false){
        const { activeTab } = this.props.state;
        const { limit } = this.state;
        let { page } = this.state;
        
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        const requestData = {
            owner: (activeTab === 1 ? "me" : "other"),
            limit: limit,
            page: page
        }
        const source = axios.CancelToken.source();
        Api.getUserHugs(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);

            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                loading: false,
            })
        });

        this.setState({
            loading: !viewMore,
            loadingMore: viewMore,
            page: page
        })
    }
    socialShare(e, type = 'facebook', hugId){
        e.preventDefault();

        let url = '';
        if(type === 'twitter'){
            url = "http://twitter.com/share?url="+window.location.origin+"/hug/"+hugId+"&text=I used forember to spread love with forever hugs.";
        }else if(type === 'facebook'){
            url = "http://www.facebook.com/share.php?u="+window.location.origin+"/hug/"+hugId+"&title=I used forember to spread love with forever hugs.";
        }

        window.open(url,'share-dialog',"width=626,height=436");
    }
    getShareUrl(hug){
        let id = hug.id;
        if(hug.hasOwnProperty('hash') && hug.hash !== ''){
            id = hug.hash;
        }
        return window.location.origin+"/hug/"+id;
    }
    handleHugModal(status, hugId = null){
        this.setState({
            hugReminderModal: status,
            hugId: hugId
        })
    }
    onEmailModal(status, hug){
        this.setState({
            emailModal: status,
            hug: hug
        })
    }
    inDraft(item){
        if(item.draft === 1 && item.photo_id === 0 && item.video_id === 0){
            return true;
        }
        return false;
    }
    getRecipentNames(hug){
        let hugRecipients = hug.recipients;

        let recipientNames = "";
        hugRecipients.map((item, key) => {
            recipientNames += item.first_name;

            if (key + 1 !== hugRecipients.length) {
                recipientNames += ", ";
            }
            
            return null;
        });

        return recipientNames;
    }
    renderHugOptions(item, user){
        return(
            <div className="sd-hug-options">
                {
                    user.id === item.owner_id ?
                        <>
                            {
                                item.draft !== 1 ?
                                    <span className="sd-email-hug">
                                        <Button className={"button"} onClick={() => this.onAddRecipientModal(true, item)}>
                                            Send Hug
                                        </Button>
                                    </span>
                                :
                                    <></>
                            }
                            <RouterLink to={"/hug/edit/"+item.hash} onClick={() => this.onLinkClick("/hug/edit/"+item.hash)} >
                                <span className="sd-edit-hug" title="Edit Hug" >
                                    <ModeEdit />
                                </span>
                            </RouterLink>
                        </>
                    :
                        <>
                            <span className="sd-schedule-hug" title="Add Reminder" onClick={()=> this.handleHugModal(true, item.id)}>
                                <CalendarTodayOutlined />
                            </span>
                            <span className="sd-home-hug-socialshare" title="Share Hug" >
                                <InlineShareButtons
                                    config={{
                                        color: 'social',
                                        enabled: true,
                                        radius: 5, 
                                        size: 26,
                                        url: this.getShareUrl(item),
                                        title: 'I used forember to spread love with forever hugs.',
                                        networks: [
                                            'sharethis',
                                        ]
                                    }}
                                />
                            </span>
                        </>
                }
            </div>
        )
    }
    renderTileItem(item, key, user){
        return(
            <div className="sd-hug-item" key={key}>
                { this.renderHugOptions(item, user) }
                <RouterLink className={this.inDraft(item) ? 'nocursor' : ''} to={this.inDraft(item) ? "/home" : "/hug/"+item.hash} onClick={this.inDraft(item) ? '' : () => this.onLinkClick("/hug/"+item.hash)} >
                    <div className='sd-hug-content'>
                        {
                            this.inDraft(item) ?
                                <></>
                            :
                                <div className="play-icon">
                                    <div className="play-icon-circle">
                                        <PlayArrow />
                                    </div>
                                </div>
                        }
                        <img className={this.inDraft(item) ? 'border' : ''} src={item.photo_url} alt="video_img" />
                    </div>
                </RouterLink>
                <p className="video-duration">{ item.duration }</p>
            </div>
        )
    }
    renderItems(){
        const { user, hugs, activeTab } = this.props.state;
        const { response } = this.state;
        const { history } = this.props;
        if(response === null){
            return null;
        }

        let pendingHugs = this.getPendingHugs();
        let items = [];
        if(activeTab === 1 && hugs.length > 0 && pendingHugs.length > 0){
            items.push(
                <Progressbar history={history} layout="tile" onGenerateHugs={(data) => this.onGenerateHugs(data)} />
            )
        }
        response.map((item, key) => {
            items.push(
                this.renderTileItem(item, key, user)
            );

            return null;
        });
        return items;
    }
    getPendingHugs(){
        const { hugs } = this.props.state;
        const { response } = this.state;

        if(response === null || hugs.length <= 0){
            return [];
        }
        
        let pendingHugIds = [];
        hugs.map(hugId => {
            let found = response.some(obj => {
                return obj.id === hugId;
            });
            if(found === false){
                pendingHugIds.push(hugId);
            }
            return null;
        });

        if(pendingHugIds.length <= 0){
            this.props.dispatch({type: 'UPDATE_STATE',state: {hugs: [], hugsGenerated: false}});
        }
        
        return pendingHugIds;
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    onShowFullNotes(itemId){
        const { showFullNotes } = this.state;
        const currentIndex = showFullNotes.indexOf(itemId);
        const newShowFullNotes = [...showFullNotes];
    
        if (currentIndex === -1) {
            newShowFullNotes.push(itemId);
        } else {
            newShowFullNotes.splice(currentIndex, 1);
        }
    
        this.setState({
            showFullNotes: newShowFullNotes,
        });

    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getColumns(user){
        const { activeTab } = this.props.state;
        let columns = [
            {
                Header: "Hug",
                accessor: "video",
                headerClassName: "hd_video",
                className: "hd_video td_video",
                resizable: false,
                sortable: false,
            },
            {
                Header: "Greeting",
                accessor: "greeting",
                headerClassName: "hd_greeting",
                className: "hd_greeting td_greeting",
                resizable: false,
                sortable: false,
            },
            {
                Header: "Date",
                accessor: "date",
                headerClassName: "hd_date",
                className: "hd_date td_date",
                resizable: false,
                sortable: false,
            },
        ];
        if(activeTab === 1){
            columns.push(
                {
                    Header: "To",
                    accessor: "to",
                    headerClassName: "hd_to",
                    className: "hd_to td_to",
                    resizable: false,
                    sortable: false,
                },
                {  
                    Header: "Hug Delivery",
                    accessor: "hug_delivery",
                    headerClassName: "hd_hug-delivery",
                    className: "hd_hug-delivery td_hug-delivery",
                    resizable: false,
                    sortable: false,
                },
            )
        }else{
            columns.push(
                {
                    Header: "From",
                    accessor: "from",
                    headerClassName: "hd_to",
                    className: "hd_to td_to",
                    resizable: false,
                    sortable: false,
                },
            )
        }
        columns.push(
            {
                Header: "Views",
                accessor: "views",
                headerClassName: "hd_views",
                className: "hd_views td_views",
                resizable: false,
                sortable: false,
            },
        )
        if(activeTab === 1){
            columns.push(
                {
                    Header: "Edit",
                    accessor: "edit",
                    headerClassName: "hd_edit",
                    className: "hd_edit td_edit",
                    resizable: false,
                    sortable: false,
                },
            )
        }
        if(activeTab === 2){
            columns.push(
                {
                    Header: "Reminder",
                    accessor: "reminder",
                    headerClassName: "hd_reminder",
                    className: "hd_reminder td_reminder",
                    resizable: false,
                    sortable: false,
                },
            )
        }
        columns.push(
            {
                Header: "Share",
                accessor: "share",
                headerClassName: "hd_share",
                className: "hd_share td_share",
                resizable: false,
                sortable: false,
            },
        )

        return columns;
    }
    getTableData(){
        const { user, hugs, activeTab } = this.props.state;
        const { response, showFullNotes } = this.state;
        const { history } = this.props;
        if(response === null){
            return [];
        }

        let tableData = [];
        let pendingHugs = this.getPendingHugs();
        if(activeTab === 1 && hugs.length > 0  && pendingHugs.length > 0){
            hugs.map((hug, key) => {
                let hugArray = {
                    id: "",
                    video: <Progressbar history={history} layout="list" hug={hug} key={key} onGenerateHugs={(data) => this.onGenerateHugs(data)} />,
                    greeting: "",
                    date: "",
                    to: "",
                    from: "",
                    hug_delivery: "",
                    views: "",
                    edit: "",
                    reminder: "",
                    share: ""
                };

                tableData.push(hugArray);
                return null;
            });
        }
        response.map(item => {
            let hugArray = {
                id: item.id,
                video: this.renderTileItem(item, null, user),
                greeting: (
                    <p onClick={() => this.onShowFullNotes(item.id)}>
                        { 
                            showFullNotes.includes(item.id) ?
                                item.notes 
                            :
                                item.notes.length > 50 ? item.notes.substring(0, 50)+'...' : item.notes
                        }
                    </p>
                ),
                date: moment(item.updated_at, 'YYYYMMDD hh:mm:ss').utc('0').fromNow(),
                to: this.getRecipentNames(item),
                from: item.owner.first_name,
                hug_delivery: (
                    <>
                        {
                            item.draft !== 1 ? 
                                item.hug_sent === 1 ?
                                    <>
                                        <p>Sent: {moment(item.hug_sent_date).format('ll')}</p>
                                        <Button className={"button"} onClick={() => this.onAddRecipientModal(true, item)}>
                                            Send Again
                                        </Button>
                                    </>
                                :
                                    <Button className={"button"} onClick={() => this.onAddRecipientModal(true, item)}>
                                        Send Hug Now
                                    </Button>
                            :
                                <></>
                        }
                    </>
                ),
                views: item.views,
                edit: (
                    <RouterLink to={"/hug/edit/"+item.hash} onClick={() => this.onLinkClick("/hug/edit/"+item.hash)} >
                        <span className="sd-edit-hug" title="Edit Hug" >
                            <ModeEdit />
                        </span>
                    </RouterLink>
                ),
                reminder: (
                    <span className="sd-schedule-hug" title="Add Reminder" onClick={()=> this.handleHugModal(true, item.id)}>
                        <CalendarTodayOutlined />
                    </span>
                ),
                share: (
                    <>
                        {
                            item.draft !== 1 ? 
                                <span className="sd-home-hug-socialshare" title="Share Hug" >
                                    <InlineShareButtons
                                        config={{
                                            color: 'social',
                                            enabled: true,
                                            radius: 5, 
                                            size: 26,
                                            url: this.getShareUrl(item),
                                            title: 'I used forember to spread love with forever hugs.',
                                            networks: [
                                                'sharethis',
                                            ]
                                        }}
                                    />
                                </span>
                            :
                                <></>
                        }
                    </>
                )
            };

            tableData.push(hugArray);
            return null;
        });

        return tableData;
    }
    onAddRecipientModal(status = false, hug = null){
        let stateObj = {
            addRecipientModal: status,
            hug: hug
        };

        this.setState(stateObj);
    }
    onAddRecipients(recipients){
        const { hug } = this.state;

        let hugObj = hug;
        hugObj.recipients = [];
        recipients.map(recipient => {
            hugObj.recipients.push(recipient);

            return null;
        });

        this.setState({
            hug: hugObj
        })
    }
    onSendEmail(hugResponse){
        const { hug } = this.state;

        let hugObj = hug;
        hugObj.hug_sent = hugResponse.hug_sent;
        hugObj.hug_sent_date = hugResponse.hug_sent_date;
        hugObj.recipients = hugResponse.recipients;

        this.setState({
            hug: hugObj,
            addRecipientModal: false,
        }, () => {
            this.onSuccessModal(true);
        });
    }
    onSuccessModal(status = false){
        this.setState({
            emailSuccessModal: status
        })
    }
    onGenerateHugs(hugs){
        const { response } = this.state;

        let pendingHugs = [];
        hugs.map(hug => {
            let found = response.some(obj => {
                return obj.id === hug.id;
            });
            if(found === false){
                pendingHugs.push(hug);
            }
            return null;
        });

        const mergedArray = pendingHugs.concat(this.state.response);

        this.setState({
            response: mergedArray
        })
    }
    render() {
        const { user, layout, activeTab } = this.props.state;
        const { loading, loadingMore, response, hugReminderModal, hugId, emailModal, hug, addRecipientModal, emailSuccessModal,
        page, totalPages } = this.state;
        const { history } = this.props;

        return (
            <>
                <div className="sd-section-bg">
                    <div className="container">
                        <div className="sd-user-homepage">
                            <div className="sd-home-welcome-note">
                                <h4>Welcome { user.first_name },<span> Thank you for being an early adopter for Forember! After using the service, please complete the <RouterLink to="https://docs.google.com/forms/d/e/1FAIpQLSd2fNibOjT5Q2GOiCjF-0vnPs6JALWabnTd0c-czppMPXBuXw/viewform?embedded=true&pli=1" target="_blank">Feedback Survey</RouterLink> which will help us continue to improve your experience. Your input is appreciated!</span></h4>
                            </div>
                            <div className="sd-user-hugs-container">
                                <div className="sd-hugs-buttons-group">
                                    <RouterLink to="/create-hug" onClick={() => this.onLinkClick("/create-hug")}>
                                        <Button className={"button"}>
                                            <AddOutlined />Create new hug
                                        </Button>
                                    </RouterLink>
                                    <div className="sd-hugs-filter-buttons">
                                        <div className="sd-hugs-layout-types">
                                            <span className={"tile-icon "+(layout === 'tile' ? 'active' : '')} onClick={() => this.handleChangeLayout('tile')}>
                                                <img src={TileIcon} alt="tile-icon" title="Tile View" />Tile View
                                            </span>
                                            <span className={"list-icon "+(layout === 'list' ? 'active' : '')}  onClick={() => this.handleChangeLayout('list')}>
                                                <img src={ListIcon} alt="list-icon" title="Details View" />Details View
                                            </span>
                                        </div>
                                        <div className="tabbed-button">
                                            <Button className={activeTab === 1 ? 'active' : ''} onClick={()=> this.handleTabChange(1)}>
                                                <span className="label">Forever Hugs For Others</span>
                                            </Button>
                                            <Button className={activeTab === 2 ? 'active' : ''} onClick={()=> this.handleTabChange(2)}>
                                                <span className="label">Forever Hugs For Me</span>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sd-hugs-content-container">
                                <div className={"sd-hugs-items-container "+(layout === 'tile' ? 'tile-items' : 'list-items')}>
                                    {
                                        !loading && response !== null && response.length > 0 ?
                                            <>
                                                {
                                                    layout === 'tile' ?
                                                        this.renderItems()
                                                    :
                                                        <div className="sd-hugstable-container">
                                                            <ReactTable
                                                                columns={this.getColumns(user)}
                                                                data={this.getTableData()}
                                                                pageSizeOptions={[500]}
                                                                defaultPageSize={50000000000}
                                                                showPaginationTop={false}
                                                                minRows={0}
                                                                showPaginationBottom={false}
                                                                className={"-striped -highlight hugsTable"}
                                                                getNoDataProps={() => this.getNoDataProps()}
                                                            />
                                                        </div>
                                                }
                                            </>
                                        :
                                            !loading && (response === null || response.length <= 0) ?
                                                activeTab === 1 ?
                                                    <span>You have not created any hugs for others.</span>
                                                :
                                                    <span>Currently no one created any hug for you.</span>
                                            :
                                            null
                                    }
                                </div>
                                {
                                    response !== null && response.length > 0 && loading === false && loadingMore === false && page < totalPages ?
                                        <div className={"sd-load-more"}>
                                            <Button onClick={(e) => this.getUserHugs(true)}>
                                                <Refresh />View More
                                            </Button>
                                        </div>
                                    :
                                        loading || loadingMore ?
                                            <LoaderComponent color="custom" align="center" />
                                        :
                                            <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {
                    hugReminderModal ?
                        <HugReminderModal 
                            open={hugReminderModal}
                            onClose = {(status) => this.handleHugModal(status)}
                            id={hugId}
                            history={history}
                        />
                    :
                        <></>
                }
                {
                    emailModal ? 
                        <EmailModal
                            open={emailModal}
                            onClose = {() => this.onEmailModal(false)}
                            onAddRecipient = {() => this.onAddRecipientModal(true)}
                            onSendEmail = {(hugResponse) => this.onSendEmail(hugResponse)}
                            hug={hug}
                            history={history}
                        />
                    :
                        <></>
                }
                {
                    addRecipientModal ?
                        <AddRecipientModal
                            open={addRecipientModal}
                            onClose={() => this.onAddRecipientModal(false)}
                            hug={hug}
                            onAddRecipients={(recipients) => this.onAddRecipients(recipients)}
                            onSendEmail = {(hugResponse) => this.onSendEmail(hugResponse)}
                            history={history}
                        />
                    :
                        <></>
                }
                {
                    emailSuccessModal ?
                        <SuccessModal
                            open={emailSuccessModal}
                            title="Your hug has been sent."
                            desc="Thank you for spreading love with forever hugs!"
                            buttonText="Close"
                            onClose={() => this.onSuccessModal(false)}
                            onButtonAction={() => this.onSuccessModal(false)}
                        />
                    :
                        <></>
                }
            </>
        );
    }
};

export default connect(mapStateToProps)(Home);