import React from 'react';
import PropTypes from "prop-types";

const DragNDrop = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        this.ref = React.createRef();

        this.state = {

        };

        this.onDragOver = this.onDragOver.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }
    componentDidMount(){
        this.init();
    }
    componentWillUnmount(){
        this.ref.current.removeEventListener("dragover", this.onDragOver);
        this.ref.current.removeEventListener("dragstart", this.onDragStart);
        this.ref.current.removeEventListener("drop", this.onDrop);
    }
    init(){
        this.ref.current.addEventListener("dragover", this.onDragOver);
        this.ref.current.addEventListener("dragstart", this.onDragStart);
        this.ref.current.addEventListener("drop", this.onDrop);
    }
    onDragOver(e){
        e.preventDefault();
        e.stopPropagation();
    }
    onDragStart(e){
        e.preventDefault();
        e.stopPropagation();
    }
    async onDrop(e){
        e.preventDefault();
        e.stopPropagation();

        let files = [];
        for (var i = 0; i < e.dataTransfer.files.length; i++) {
            files[i] = e.dataTransfer.files[i];
        }
        
        let filesArray = [];
        if (files.length) {
            const items = e.dataTransfer.items;
            if (items && items.length && items[0].webkitGetAsEntry != null) {
                filesArray = await this.addFilesFromItems(items);
                this.props.onDrop(filesArray);
            }else{
                for (let i = 0; i < files.length; i++) {
                    filesArray.push({
                        type: "file",
                        file: files[i]
                    });
                }
                this.props.onDrop(filesArray);
            }
        }
    }
    async addFilesFromItems(items, path = ""){
        const that = this;
        let filesArray = [];
        for (let i = 0; i < items.length; i++) {
            var item = items[i];
            var entry;
            if (item.webkitGetAsEntry != null && (entry = item.webkitGetAsEntry())) {
                if (entry.isFile) {
                    filesArray.push({
                        type: "file",
                        file: item.getAsFile()
                    });
                } else if (entry.isDirectory) {
                    const newFilesArray = await that.addFilesFromDirectory(entry, path + entry.name + "/");
                    filesArray.push({
                        type: "folder",
                        name: entry.name,
                        files: newFilesArray,
                        checkRelativePath: true
                    });
                }
            } else if (item.getAsFile != null) {
                filesArray.push({
                    type: "file",
                    file: item.getAsFile()
                });
            }
        }
        return filesArray;
    }
    async addFilesFromDirectory(directory, path, filesArray = []){
        return await new Promise((resolve, reject) => {
            const that = this;
            const dirReader = directory.createReader();

            var readEntries = async function readEntries() {
                let entries = await that.readEntriesPromise(dirReader);
                if (entries.length > 0) {
                    for (var i=0; i < entries.length; i++) {
                        var entry = entries[i];
                        if (entry.isFile) {
                            const file = await that.readEntryFile(entry);
                            filesArray.push({
                                type: "file",
                                file: file,
                                webkitRelativePath: path+file.name
                            });
                        } else if (entry.isDirectory) {
                            await that.addFilesFromDirectory(entry, path + entry.name + "/", filesArray);
                        }
                    }
                    readEntries();
                }else{
                    resolve(filesArray);
                }
            };
            readEntries();
        });
    }
    async readEntriesPromise(directoryReader) {
        try {
          return await new Promise((resolve, reject) => {
            directoryReader.readEntries(resolve, reject);
          });
        } catch (err) {
          console.log(err);
        }
    }
    async readEntryFile(entry){
        try {
            return await new Promise((resolve, reject) => {
                entry.file(resolve, reject);
            });
        } catch (err) {
            console.log(err);
        }
    }
    hasDirectory(items){
        let hasDirectory = false;
        for (let i = 0; i < items.length; i++) {
            var item = items[i].webkitGetAsEntry();
            if(item && item.isDirectory){
                hasDirectory = true;
                break;
            }
        }
        return hasDirectory;
    }

    traverseFileTree(item, path, filesArray){
        path = path || "";
        const that = this;
        if (item.isFile) {
            // Get file
            item.file(function(file) {
                filesArray.push({
                    type: "file",
                    file: file
                });
            });
        } else if (item.isDirectory) {
            // Get folder contents
            var dirReader = item.createReader();
            let newFilesArray = [];
            dirReader.readEntries(function(entries) {
                for (var i=0; i<entries.length; i++) {
                    newFilesArray = that.traverseSubFileTree(entries[i], path + item.name + "/", newFilesArray);
                }
            });
            filesArray.push({
                type: "folder",
                name: path + item.name,
                files: newFilesArray,
                checkRelativePath: true
            });
        }
        return filesArray;
    }
    traverseSubFileTree(item, path, filesArray){
        path = path || "";
        const that = this;
        if (item.isFile) {
            // Get file
            item.file(function(file) {
                filesArray.push({
                    type: "file",
                    file: file,
                    webkitRelativePath: path+file.name
                });
            });
        } else if (item.isDirectory) {
            // Get folder contents
            var dirReader = item.createReader();
            dirReader.readEntries(function(entries) {
                for (var i=0; i<entries.length; i++) {
                    that.traverseSubFileTree(entries[i], path + item.name + "/", filesArray);
                }
            });
        }
        return filesArray;
    }
    render() {
        const { children } = this.props;
        return (
            <div ref={this.ref}>
                {children}
            </div>
        )
    }
}

DragNDrop.propTypes = {
    classes: PropTypes.object,
    onDrop: PropTypes.func
};

export default DragNDrop;
