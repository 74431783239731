
import React from "react";
import { List, ListItem, Button } from "@mui/material";
import logo from "../../assets/img/forember_logo.png";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/reducers/rootReducer";

import "../../assets/scss/footerStyle.scss";

class Footer extends React.Component {
    onLinkClick(href){
        this.props.history.push(href);
    }
    render() {
        const today = new Date();
        const { authorized, url } = this.props.state;

        return (
            <div className="sd-footer-section">
                {
                    authorized ?
                        <div className="sd-user-footer">
                            <div className="container">
                                <p className="copyright">Copyright © {today.getFullYear()} Forember</p>
                                <List className={"menu-list"}>
                                    <ListItem className={"listItem"}>
                                        <RouterLink to="/contact-us" className={"navLink"} onClick={() => this.onLinkClick("/contact-us")}>Contact Us</RouterLink>
                                    </ListItem>
                                    <ListItem className={"listItem"}>
                                        <RouterLink to="/privacy-policy" className={"navLink"} onClick={() => this.onLinkClick("/privacy-policy")}>Privacy Policy</RouterLink>
                                    </ListItem>
                                    <ListItem className={"listItem"}>
                                        <RouterLink to="/terms" className={"navLink"} onClick={() => this.onLinkClick("/terms")}>Terms of Service</RouterLink>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                    :
                    <>
                        {
                            url !== '/create-hug' ?
                                <div className="sd-footer-create-section">
                                    <h3>Create a Forever Hug for free in less than 10 minutes.</h3>
                                    <div className="sd-footer-create-btn">
                                        <RouterLink to="/create-hug" onClick={() => this.onLinkClick("/create-hug")} className={"buttonlink"}>
                                            <Button className={"button radius-10"}>Create your free hug</Button>
                                        </RouterLink>
                                    </div>
                                </div>
                            :
                                <></>
                        }
                        <div className="sd-footer-links-section">
                            <div className="container">
                                <div className="sd-footer-logo-section">
                                    <div>
                                        <img src={logo} alt="formeber-logo" />
                                    </div>
                                    <div>
                                    <List className={"list"}>
                                        <ListItem className={"listItem"}>
                                            <RouterLink to="/contact-us" onClick={() => this.onLinkClick("/contact-us")} className={"navLink"} >Contact Us</RouterLink>
                                        </ListItem>
                                        <ListItem className={"listItem"}>
                                            <RouterLink to="/privacy-policy" onClick={() => this.onLinkClick("/privacy-policy")} className={"navLink"}>Privacy Policy</RouterLink>
                                        </ListItem>
                                        <ListItem className={"listItem"}>
                                            <RouterLink to="/terms" onClick={() => this.onLinkClick("/terms")} className={"navLink"}>Terms of Service</RouterLink>
                                        </ListItem>
                                    </List>
                                    </div>
                                </div>
                                <p className="copyright">© {today.getFullYear()} Forember LLC.</p>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps)(Footer);
