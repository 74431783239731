import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { PlayArrow, Close } from "@mui/icons-material";

import "../../../assets/scss/videoModalStyle.scss";

class VideoModal extends React.Component {
    constructor(props){
        super(props);

        this.playButton = React.createRef();
        this.videoPlayer = React.createRef();
    }
    onVideoPlay(){
        this.playButton.current.style.display = "none";
    }
    onVideoPause(){
        this.playButton.current.style.display = "";
    }
    render() {
        return (
            <>
                <Dialog
                    maxWidth="lg"
                    fullWidth={true}
                    open={this.props.open}
                    className="sd-hug-video-modal"
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.handleModal(false)}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className="sd-hug-video-modal-content">
                            <div className="play-icon" ref={this.playButton}>
                                <div className="play-icon-circle">
                                    <PlayArrow />
                                </div>
                            </div>
                            <video controls autoPlay="autoplay" onPause={() => this.onVideoPause()} onPlay={() => this.onVideoPlay()} ref={this.videoPlayer}>
                                <source src={this.props.videoSrc} />
                            </video>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}
export default VideoModal;