import React from 'react';
import PropTypes from "prop-types";
import LoaderComponent from "./Loader";

import "../../assets/scss/RecaptchaStyle.scss";

class RecaptchaV2 extends React.Component {
    constructor(props){
        super(props);
        this.gRecaptcha = React.createRef();
        this.renderId = null;
        this.onChange = this.onChange.bind(this);

        this.state = {
            loading: false,
            show: this.props.show,
            token: null
        };
    }
    componentDidMount(){
        if(this.gRecaptcha.current === null){
            return;
        }

        if(typeof(window.grecaptcha) === "object" && typeof(window.grecaptcha.render) === "function"){
            this.renderCaptcha();
            return;
        }

        setTimeout(() => {
            if(typeof(window.grecaptcha) === "object" && typeof(window.grecaptcha.render) === "function"){
                this.renderCaptcha();
                return;
            }
        },2000);

        setTimeout(() => {
            if(typeof(window.grecaptcha) === "object" && typeof(window.grecaptcha.render) === "function" && this.renderId === null){
                this.renderCaptcha();
                return;
            }
        },3000);

        setTimeout(() => {
            if(typeof(window.grecaptcha) === "object" && typeof(window.grecaptcha.render) === "function" && this.renderId === null){
                this.renderCaptcha();
                return;
            }
        },4000);
    }
    componentWillReceiveProps(props){
        if(props.resetCaptcha){
            this.resetCaptcha();
            this.props.resetCaptchaStatus();
        }
    }
    renderCaptcha(){
        try{
            this.renderId = window.grecaptcha.render(this.gRecaptcha.current, {
                'sitekey' : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
                'callback': this.onChange
            });
        }catch(e){}
    }
    resetCaptcha(){
        this.renderId = window.grecaptcha.reset(this.renderId);
    }
    onChange(token){
        this.setState({token: token});
        if(this.props.onChange){
            this.props.onChange(token);
        }
    }
    render(){
        const { loading } = this.state;
        return (
            <div className={"formControl "+(loading ? "loading" : "")}>
                {
                    loading ?
                        <LoaderComponent align="left" />
                    :
                        <></>
                }
                <div className="g-recaptcha" ref={this.gRecaptcha}></div>
            </div>
        )
    }
}

RecaptchaV2.defaultProps = {
    onChange: null,
    show: false
}
RecaptchaV2.propTypes = {
    classes: PropTypes.object,
    onChange: PropTypes.func,
    show: PropTypes.bool
};

export default RecaptchaV2;