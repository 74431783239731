import { Button } from "@mui/material";
import React from "react";
import heartIcon from "../../../assets/img/heart_icon.png";
import { Link as RouterLink } from "react-router-dom";

import "../../../assets/scss/step5Style.scss";

const Step5 = class extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            edit: false
        }

        if(this.props.state !== null){
            this.state = this.props.state;
        }
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    isValid(){
        return true;
    }
    render() {
        const { edit } = this.state;

        return (
            <div className="sd-step-five-content">
                <div className="sd-step5-note">
                    <img src={heartIcon} alt="heart-icon" />
                    <h1>Congratulations!</h1>
                    <p className="small">Your Forever hug is being { edit ? 'updated' : 'created' } and will be available on your dashboard shortly. To share with your loved one, click the “<span className="bold">Send Hug</span>” button once it's ready. Thank you for spreading love with Forever Hugs!</p>
                    <RouterLink to={"/home"} className="buttonlink" onClick={() => this.onLinkClick("/home")} >
                        <Button className="button radius-8">Go To Dashboard</Button>
                    </RouterLink>
                </div>
            </div>
        );
    }
}

export default Step5;