// import { combineReducers } from 'redux';
import simpleReducer from './simpleReducer';
// export default combineReducers({
//  simpleReducer
// });
export default simpleReducer;

const mapStateToProps = (state) => {
    return {
        state: state
    }
};
export { mapStateToProps };