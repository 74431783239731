import React, { Component } from "react";
import whoWeAreImg from "../../assets/img/who-we-are.png";
import ourWhyImg from "../../assets/img/home_video_img.png";
import hugVideo from "../../assets/video/grandma_ruth_hug_for_forember_site.mp4";
import VideoModal from "../components/Modal/VideoModal";
import { Button } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

import "../../assets/scss/aboutUsStyle.scss";

class AboutUs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            videoModal: false
        };
    }
    handleVideoModal(status){
        this.setState({
            videoModal: status
        });
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    render() {
        return (
            <>
                <div className="container">
                    <div className="sd-about-us-container">
                        <div className="our-mission">
                            <h1>ABOUT US</h1>
                            <h2>SPREAD LOVE WITH FOREVER HUGS</h2>
                        </div>
                        <div className='sd-about-us-content'>
                            <div className='sd-about-us-content-section'>
                                <p>Forember, founded by the husband-and-wife team of Matt and Rachel, is driven by a mission to spread love. The origin of Forember combines "Forever," signifying everlasting connections, and "Remember," representing the treasured love from others that remains in our hearts. "Ember," also found in the name, symbolizes the lasting warmth of love and memories that glow after the flames fade. Forember captures this warmth with Forever Hugs, heartfelt messages that take just minutes to complete. These messages include a personal voice recording, cherished photos, and the option of scheduled reminders. With simplicity, every element is carefully designed to preserve the essence of love and connection. Inspired by their grandparents, supported by their parents, and now as parents themselves, Matt and Rachel believe forever hugs offer peace of mind, and they can do the same for you too.</p>
                            </div>
                            <div className='sd-about-us-image-section'>
                                <img src={whoWeAreImg} alt="about_us_img" />
                            </div>
                        </div>
                        <div className='sd-about-us-content'>
                            <div className='sd-about-us-content-section'>
                                <p>As the world shut down during the pandemic, we opened our doors and hearts to 3 amazing boys. From foster care to adoption, these boys became part of our Forever Family. The pandemic revealed challenges of isolation, uncertainty, and declining mental health, which sparked Forember’s mission. Forember provided a unique opportunity for us to express our love for our boys, offering them comfort and security during uncertain times. Our boys also bonded with Great Grandma Ruth during this time. We found creative ways to connect and joyfully celebrate her 101st birthday with a 101 Dalmation themed party. A Forever Hug by Great Grandma was made for our boys, to ensure that her love will continue to comfort them long after she's gone. With personalized reminders, Forember will preserve her memory for them, ensuring that at any time they can receive her hug and feel the warmth of her love.</p>
                            </div>
                            <div className='sd-about-us-image-section'>
                                <div className="sd-about-us-image-play">
                                    <div className="play-icon" onClick={()=> this.handleVideoModal('hugVideoModal',true)}>
                                        <div className="play-icon-circle">
                                            <PlayArrow />
                                        </div>
                                    </div>
                                    <img onClick={()=> this.handleVideoModal(true)} src={ourWhyImg} alt="about_us_img" />
                                </div>
                                <p onClick={()=> this.handleVideoModal(true)}>Click here to view Forever Hug from great Grandma Ruth</p>
                            </div>
                        </div>
                        <div className="sd-join-community">
                            <p>JOIN THE FOREMBER COMMUNITY AND START SHARING IN THE POWER OF EVERLASTING CONNECTIONS.</p>
                            <RouterLink to="/create-hug" onClick={() => this.onLinkClick("/create-hug")} className={"buttonlink"}>
                                <Button className={"button radius-10"}>
                                    Create Forever Hug
                                </Button>
                            </RouterLink>
                        </div>
                    </div>
                </div>
                {
                    this.state.videoModal ?
                        <VideoModal 
                            open={this.state.videoModal}
                            handleModal = {(status) => this.handleVideoModal(status)}
                            videoSrc={hugVideo}
                        />
                    :
                        <></>
                }
            </>
        )
    }
};

export default AboutUs;
