import React from "react";
import { Dialog, DialogContent, DialogActions, DialogTitle, Button, IconButton } from "@mui/material";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AddOutlined, Close, Error, DeleteOutlineOutlined } from "@mui/icons-material";
import { helper } from '../../../assets/js/utils/Element';
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import LoaderComponent from '../../components/Loader';
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import { Link as RouterLink } from "react-router-dom";

import "../../../assets/scss/hugReminderModalStyle.scss";

class HugReminderModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            emailCheckbox: false,
            phoneCheckbox: false,
            email: "",
            phone: "",
            phoneCheckmark: false,
            reminders: [
                {
                    date: '',
                    occasion: '',
                }
            ],
            validation: {
                email: '',
                phone: '',
                phoneCheckmark: '',
                isValid: false
            },
            occasionAdd: "",
            occasionOptions: this.occasionOptions(),
            loading: false,
            saving: false,
            isValidate: false,
            hugReminderResponse: null,
            saved: false,
        }
    }
    componentDidMount(){
        this.getHugReminders();
    }
    getHugReminders(){
        const { id } = this.state;
        const source = axios.CancelToken.source();
        this.setState({
            loading: true,
        })
        Api.getHugReminders(id, source).then(data => {
            this.setState({
                hugReminderResponse: data,
                emailCheckbox: data.email.length > 0 ? true : false,
                phoneCheckbox: data.phone.length > 0 ? true : false,
                email: data.email,
                phone: data.phone,
                reminders: data.reminder_dates,
                loading: false,
                occasionOptions: this.getOccasionOptions(data),
                phoneCheckmark: data.phone.length > 0 ? true : false
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                loading: false,
            });
        });
    }
    getOccasionOptions(data){
        let occasionOptions = this.occasionOptions();
        let occasionArray = [];
        occasionOptions.map(occasion => {
            occasionArray.push(occasion.key);

            return null;
        });

        data.reminder_dates.map(reminder => {
            if(!occasionArray.includes(reminder.occasion)){
                occasionOptions.push({
                    key: reminder.occasion,
                    value: reminder.occasion
                });
            }

            return null;
        });

        return occasionOptions;
    }
    occasionOptions(){
        const options = [
            {
                key: 'occasion',
                value: 'Occasion'
            },
            {
                key: 'birthday',
                value: 'Birthday'
            },
            {
                key: 'anniversary',
                value: 'Anniversary'
            },
            {
                key: 'holiday',
                value: 'Holiday'
            },
            {
                key: 'date-of-passing',
                value: 'Date of passing'
            }
        ];

        return options;
    }
    handleCheckbox(e, name){
        const { user } = this.props.state;

        let state = {};
        state[name] = e.target.checked;

        if(name === "emailCheckbox" && this.state.email === '' &&  e.target.checked){
            state['email'] = user.email;
        }

        this.setState(state);
    }
    handleChange(e, name){
        let state = {};
        state[name] = e.target.value;
        if(name === "email"){
            state[name] = e.target.value.toLowerCase();
        }
        this.setState(state);
    }
    handleDateChange(date, key, name){
        let reminders = this.state.reminders;        
        reminders[key][name] = date;

        this.setState({
            reminders: reminders
        });
    }
    handleOccasionType(e){
        this.setState({
            occasionAdd: e.target.value
        })
    }
    handleOccasionAdd(e, key, name){
        e.preventDefault();

        let reminders = this.state.reminders;
        let value = this.state.occasionAdd;

        reminders[key][name] = value;

        this.setState({
            occasionAdd: "",
            reminders: reminders,
            occasionOptions: [
                ...this.state.occasionOptions,
                {
                    key: value,
                    value: value
                }
            ],
        });
    }
    handleOccasionChange(e, key, name){
        let reminders = this.state.reminders;
        let value = e.target.value;
        
        reminders[key][name] = value;

        this.setState({
            reminders: reminders
        });
    }
    handlePhoneChange(e, name) {
        const { phone } = this.state;
        this.tryValidation = true;
        let state = {};
        const value = e.target.value;
        state[name] = value;
        const regex = /^[+\d]+$/;
        if((value.length > 0 && !regex.test(value)) || value.length > 16){
            state[name] = phone;
        }
        this.setState({
            [name]: state[name]
        });
    }
    cloneRemindSet = () => {
        this.setState({
            reminders: [
                ...this.state.reminders,
                {
                    date: '',
                    occasion: '',
                }
            ]
        })
    }
    removeReminder(reminder){
        let { reminders }  = this.state;

        let pendingReminders = reminders.filter(i => i.id !== reminder.id);
        
        this.setState({
            reminders: pendingReminders
        })
    }
    validateForm(){
        const { emailCheckbox, phoneCheckbox, email, phone, phoneCheckmark, reminders } = this.state;

        let validation = {
            email: "success",
            phone: "success",
            phoneCheckmark: "success",
            isValid: true
        };

        var emailRegex = /\S+@\S+\.\S+/;
        if(emailCheckbox && (email.length <= 0 || !emailRegex.test(email))){
            validation.email = "error";
            validation.isValid = false;
        }

        const cellRegex = /^[+\d]+$/;
        if(phoneCheckbox && !cellRegex.test(phone)){
            validation.phone = "error";
            validation.isValid = false;
        }
        if(!emailCheckbox && !phoneCheckbox){
            validation.email = "error";
            validation.phone = "error";
            validation.isValid = false;
        }

        if(phoneCheckbox && !phoneCheckmark){
            validation.phoneCheckmark = "error";
            validation.isValid = false;
        }

        reminders.map(reminder => {
            if(reminder.date === ''){
                validation.isValid = false;
            }
            if(reminder.occasion.length <= 0){
                validation.isValid = false;
            }

            return null;
        });

        this.setState({
            validation: validation,
            isValidate: true
        });
        return validation.isValid;
    }
    saveReminderInfo(e){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const { reminders, emailCheckbox, phoneCheckbox, email, phone, id } = this.state;
        let requestData = {
            id: id,
            reminders: []
        };
        requestData['email'] = emailCheckbox ? email : '';
        requestData['phone'] = phoneCheckbox ? phone : '';
        reminders.map((reminder) => {
            let ISODate = null;
            if(typeof reminder.date === 'string'){
                ISODate = reminder.date;
            }else{
                ISODate = reminder.date.toISOString();
            }
            requestData.reminders.push({
                date: ISODate,
                occasion: reminder.occasion,
            });
            return null;
        });
        this.setState({
            saving: true,
        })
        const source = axios.CancelToken.source();
        Api.createHugReminders(requestData, source).then(data => {
            this.setState({
                response: data,
                saving: false,
                saved: true,
            });
            // this.props.onClose(false);
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                saving: false,
            });
        });
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    render() {
        const { occasionOptions, reminders, emailCheckbox, phoneCheckbox, email, phone, validation, occasionAdd, 
            loading, saving, phoneCheckmark, isValidate, saved } = this.state;
        const { canSendThanksEmail } = this.props;

        return (
            <>
                <Dialog
                    maxWidth="lg"
                    fullWidth={true}
                    open={this.props.open}
                    className={"sd-reminder-modal "+(saved || !canSendThanksEmail ? 'success' : '')}
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                        {
                            saved || !canSendThanksEmail ?
                                <p className={"modal-title"}>
                                    {
                                        !canSendThanksEmail ?
                                            <>You are not allowed to add a reminder because you are not the recipient of this hug.</>
                                        :
                                            <>Your reminder changes have been saved.</>
                                    }
                                </p>
                            :
                                <></>
                        }
                    </DialogTitle>
                    <DialogContent>
                        {
                            saved || !canSendThanksEmail ?
                                <></>
                            : loading ?
                                <LoaderComponent />
                            :
                                <div className="sd-reminder-model-content">
                                    <p>Choose the dates you want to have your forever hug sent to you as a reminder of your loved one.</p>
                                    <div className="sd-modal-email-note-fields">
                                        <div className="sd-flex-items">
                                            <div className="sd-remind-fields-check">
                                                <input 
                                                    type="checkbox" 
                                                    id="emailCheckbox" 
                                                    name="emailCheckbox" 
                                                    onChange={(e) => this.handleCheckbox(e, 'emailCheckbox')}
                                                    checked={emailCheckbox}
                                                />
                                                <label htmlFor="emailCheckbox">Email</label>
                                            </div>
                                            <div className="sd-remind-value">
                                                <label htmlFor="emailCheckbox">Email</label>
                                                <input 
                                                    className={validation.email === "error" ? 'error' : ''}
                                                    type="email" 
                                                    placeholder="ex@gmail.com" 
                                                    id="email" 
                                                    name="email" 
                                                    value={email}
                                                    disabled={emailCheckbox ? false : true}
                                                    onChange={(e) => this.handleChange(e,'email')}
                                                />
                                                {
                                                    validation.email === "error" ?
                                                        <Error />
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        <div className="sd-flex-items">
                                            <div className="sd-remind-fields-check">
                                                <input 
                                                    type="checkbox" 
                                                    id="phoneCheckbox" 
                                                    name="phoneCheckbox" 
                                                    onChange={(e) => this.handleCheckbox(e, 'phoneCheckbox')}
                                                    checked={phoneCheckbox}
                                                />
                                                <label htmlFor="phoneCheckbox">phone</label>
                                            </div>
                                            <div className="sd-remind-value">
                                                <label htmlFor="phoneCheckbox">Phone</label>
                                                <input 
                                                    className={validation.phone === "error" ? 'error' : ''}
                                                    type="text" 
                                                    placeholder="Enter your cell phone number" 
                                                    id="phone" 
                                                    name="phone" 
                                                    value={phone}
                                                    disabled={phoneCheckbox ? false : true}
                                                    onChange={(e) => this.handlePhoneChange(e,'phone')}
                                                />
                                                {
                                                    validation.phone === "error" ?
                                                        <Error />
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        {
                                            phoneCheckbox ?
                                                <div className={"sd-phone-checkmark"}>
                                                    <div className="sd-remind-fields-check">
                                                        <input 
                                                            type="checkbox" 
                                                            id="phoneCheckmark" 
                                                            name="phoneCheckmark" 
                                                            onChange={(e) => this.handleCheckbox(e, 'phoneCheckmark')}
                                                            checked={phoneCheckmark}
                                                        />
                                                        <label htmlFor="phoneCheckmark"></label>
                                                    </div>
                                                    <div className="sd-remind-value">
                                                        <label className={validation.phoneCheckmark === 'error' ? 'error' : ''} htmlFor="phoneCheckmark">I agree to receive text messages from Forember for Hug Reminders. Message & data rates may apply. See <RouterLink to="/privacy-policy" onClick={() => this.onLinkClick("/privacy-policy")} className={"router-link"}>Privacy Statement</RouterLink> and <RouterLink to="/terms" onClick={() => this.onLinkClick("/terms")} className={"router-link"}>Terms and Conditions</RouterLink> for details.</label>
                                                    </div>
                                                </div>
                                            :
                                                <></>
                                        }
                                    </div>
                                    <div className="sd-reminder-sets-container">
                                        <div className="sd-reminds-sets-container">
                                            {
                                                reminders.map((reminder, key) => (
                                                    <div className="sd-remind-date-occasion" key={key}>
                                                        <div className="sd-date-pick sd-fields-group">
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <Stack spacing={3}>
                                                                    <DesktopDatePicker
                                                                        className={"sd-occasion-datepick-box "+(isValidate && reminder.date === '' ? 'error' : '')}
                                                                        PopperProps={{
                                                                            className: 'sd-occasion-datepick'
                                                                        }}
                                                                        label="Date (MM/DD)"
                                                                        inputFormat="MM/DD"
                                                                        value={reminder.date}
                                                                        onChange={(date) => this.handleDateChange(date, key, 'date')}
                                                                        renderInput={(params) => <TextField {...params} />}
                                                                        shouldDisableYear={() => { return true}}
                                                                        views={['month', 'day']}
                                                                        openTo="month"
                                                                        dayOfWeekFormatter={(day) => {return day.charAt(0).toUpperCase();}}
                                                                    />
                                                                </Stack>
                                                            </LocalizationProvider>
                                                            {
                                                                isValidate && reminder.date === '' ?
                                                                    <Error />
                                                                :
                                                                    <></>
                                                            }
                                                        </div>
                                                        <div className="sd-occasion-pick sd-fields-group">
                                                            <FormControl fullWidth >
                                                                <InputLabel id="occasion-select">Occasion</InputLabel>
                                                                <Select
                                                                    MenuProps={{
                                                                        className: 'sd-occasion-select'
                                                                    }}
                                                                    labelId="occasion-select"
                                                                    id="occasion-select"
                                                                    value={reminder.occasion}
                                                                    label="Occasion"
                                                                    onChange={(e) => this.handleOccasionChange(e, key, 'occasion')}
                                                                    className={"sd-occasion-select-box "+(isValidate && reminder.occasion.length <= 0 ? 'error' : '')}
                                                                >
                                                                    { helper.renderOptions(occasionOptions) }
                                                                    <div className="sd-custom-occasion-value">
                                                                        <form onSubmit={(e) => this.handleOccasionAdd(e, key, 'occasion')}>
                                                                            <label htmlFor="other">Other</label>
                                                                            <input 
                                                                                type="text" 
                                                                                id="other" 
                                                                                name="other" 
                                                                                value={occasionAdd}
                                                                                onChange={(e) => this.handleOccasionType(e)}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key !== "Escape") {
                                                                                        e.stopPropagation();
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </form>
                                                                    </div>
                                                                </Select>
                                                            </FormControl>
                                                            {
                                                                isValidate && reminder.occasion.length <= 0 ?
                                                                    <Error />
                                                                :
                                                                    <></>
                                                            }
                                                        </div>
                                                        <span className="delete-icon" onClick={() => this.removeReminder(reminder)}>
                                                            <DeleteOutlineOutlined />
                                                        </span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="sd-add-remind-set">
                                            <Button className={"button radius-8"} onClick={this.cloneRemindSet}>Add Another Reminder <AddOutlined /></Button>
                                        </div>
                                    </div>
                                    <div className="sd-remind-save">
                                        <Button className={"button radius-8"} onClick={(e) => this.saveReminderInfo(e)}>
                                            {
                                                saving ?
                                                    <LoaderComponent />
                                                :
                                                "Save"
                                            }
                                        </Button>
                                    </div>
                                </div>
                        }
                    </DialogContent>
                    {
                        saved || !canSendThanksEmail?
                            <DialogActions
                                className={"modal-footer"}
                            >
                                <Button className="button radius-8" onClick={() => this.props.onClose()}>
                                    {
                                        !canSendThanksEmail ?
                                            <>Close</>
                                        :
                                            <>OK</>
                                    }
                                </Button>
                            </DialogActions> 
                        : 
                            <></>
                    }
                </Dialog>
            </>
        )
    }
}

HugReminderModal.defaultProps = {
    canSendThanksEmail: true
}

export default connect(mapStateToProps)(HugReminderModal);