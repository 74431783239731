import React, { Component } from "react";
import { Button } from "@mui/material";
import { Email } from "@mui/icons-material";
import { connect } from "react-redux";
import { matchPath } from 'react-router';
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import LoaderComponent from '../../components/Loader'

import "../../../assets/scss/emailUnsubscribeStyle.scss";

class EmailUnsubscribe extends Component {
    constructor(props) {
        super(props);

        let match = matchPath({
            path: "/unsubscribe/:hash",
        }, window.location.pathname);
        let hash = null;
        if(match !== null && match.params.hasOwnProperty("hash")){
            hash = match.params.hash;
        }
        this.state = {
            hash: hash,
            emailTypes: {
                hug_processed: false,
                hug_view: false,
                hug_reminder: false,
                hug_email: false,
                hug_creator_email: false,
                hug_delete: false
            }
        };
    }
    componentDidMount(){
        
    }
    handleCheckbox(e, name){
        this.setState({
            emailTypes: {
                ...this.state.emailTypes,
                [name]: e.target.checked
            }
        });
    }
    unsubscribeEmails(e){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }
        this.setState({
            savingInfo: true,
            showError: false
        });
        
        const { hash, emailTypes } = this.state;
        let requestData = {
            hash: hash,
            emailTypes: emailTypes
        };

        const source = axios.CancelToken.source();
        Api.unsubscribeEmails(requestData, source).then((data) => { 
            this.setState({
                savingInfo: false, 
            });
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    savingInfo: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
    }
    validateForm(){
        return true;
    }
    render() {
        const { hug_processed, hug_reminder, hug_email, hug_view, hug_creator_email, hug_delete } = this.state.emailTypes;

        return (
            <div className="sd-section-bg">
                <div className="container">
                    <div className="sd-email-unsubscribe">
                        <Email />
                        <h2>Do you want to unsubscribe?</h2>
                        <p>If you unsubscribe you will stop receiving emails.</p>
                        <ul>
                            <li>
                                <input 
                                    type="checkbox" 
                                    id="hug_processed" 
                                    name="hug_processed" 
                                    onChange={(e) => this.handleCheckbox(e, 'hug_processed')}
                                    checked={hug_processed}
                                />
                                <label htmlFor="hug_processed">Forever hug processed</label>
                            </li>
                            <li>
                                <input 
                                    type="checkbox" 
                                    id="hug_reminder" 
                                    name="hug_reminder" 
                                    onChange={(e) => this.handleCheckbox(e, 'hug_reminder')}
                                    checked={hug_reminder}
                                />
                                <label htmlFor="hug_reminder">Forever hug reminder</label>
                            </li>
                            <li>
                                <input 
                                    type="checkbox" 
                                    id="hug_email" 
                                    name="hug_email" 
                                    onChange={(e) => this.handleCheckbox(e, 'hug_email')}
                                    checked={hug_email}
                                />
                                <label htmlFor="hug_email">Someone sent you Forever hug</label>
                            </li>
                            <li>
                                <input 
                                    type="checkbox" 
                                    id="hug_view" 
                                    name="hug_view" 
                                    onChange={(e) => this.handleCheckbox(e, 'hug_view')}
                                    checked={hug_view}
                                />
                                <label htmlFor="hug_view">Forever hug viewed by recipient</label>
                            </li>
                            <li>
                                <input 
                                    type="checkbox" 
                                    id="hug_creator_email" 
                                    name="hug_creator_email" 
                                    onChange={(e) => this.handleCheckbox(e, 'hug_creator_email')}
                                    checked={hug_creator_email}
                                />
                                <label htmlFor="hug_creator_email">Recipient sent you thanks for sending Forever hug</label>
                            </li>
                            <li>
                                <input 
                                    type="checkbox" 
                                    id="hug_delete" 
                                    name="hug_delete" 
                                    onChange={(e) => this.handleCheckbox(e, 'hug_delete')}
                                    checked={hug_delete}
                                />
                                <label htmlFor="hug_delete">Forever hug send by other mark for deletion</label>
                            </li>
                        </ul>
                        {
                            this.state.savingInfo ?
                                <Button
                                    className={"button"}
                                >
                                    <LoaderComponent />
                                </Button>
                            :
                                <Button className={"button"} onClick={(e) => this.unsubscribeEmails(e)}>
                                    Unsubscribe
                                </Button>
                        }
                    </div>
                </div>
            </div>
        );
    }
};

export default connect(mapStateToProps)(EmailUnsubscribe);