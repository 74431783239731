import React, { Component } from "react";
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Cookie from '../../../assets/js/utils/Cookie';
import { Button } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Facebook, Google, Error } from "@mui/icons-material";
import PasswordMatch from "../../../assets/js/utils/PasswordMatch";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import LoaderComponent from '../../components/Loader'
import { LoginSocialFacebook, IResolveParams, LoginSocialGoogle } from 'reactjs-social-login';
import Config from "../../../../Config";

import "../../../assets/scss/loginStyle.scss";

class LoginModal extends Component {
    constructor(props){
        super(props);

        this.state = {
            email: "",
            password: "",
            remember: false,
            passwordConfirm: "",
            code: "",
            loading: false,
            validation: {
                email: '',
                password: '',
                passwordConfirm: '',
                code: '',
                isValid: false
            },
            showError: false,
            errorMessage: "",
            forgotPassword: this.props.forgot,
            requireLowerletter: false,
            requireUpperletter: false,
            requireNumber: false,
            requireSymbol: false,
            requireLength: false,
            requestCode: false,
            response: null,
            message: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
    }
    componentDidMount(){

    }
    componentDidUpdate(prevProps, prevState){
        const { password, passwordConfirm } = this.state;
        let validatePassword = false;
        let validatePasswordConfirm = false;
        if(prevState.password !== password){
            validatePassword = true;            
        }
        if(prevState.passwordConfirm !== passwordConfirm){
            validatePasswordConfirm = true;            
        }
        if(this.state.requestCode && (validatePassword || validatePasswordConfirm)){
            this.validateResetForm(validatePassword, validatePasswordConfirm);
        }
    }
    handleChange(e, name){
        let state = {};
        let value = e.target.value;
        if(name === "email"){
            value = value.toLowerCase();
        }
        state[name] = value;
        this.setState(state);
    }
    handleCheckbox(e, name){
        let state = {};
        state[name] = e.target.checked;
        this.setState(state);
    }
    handleSubmit(e){
        e.preventDefault();
        if(this.state.forgotPassword === true && this.state.requestCode === true){
            this.handleResetPassword();
            return;
        }
        if(this.state.forgotPassword === true){
            this.handleForgotSubmit();
            return;
        }
        const isValid = this.validateForm();
        if(!isValid || this.state.loading){
            return;
        }
        const source = axios.CancelToken.source();
        const { email, password, remember } = this.state;
        const requestData = {
            grant_type: "password",
            client_id: "2",
            client_secret: "YDAIQy9cKcL3fPifVF20eRBI0y9de2ab3UMr01KY",
            username: email,
            password: password,
            scope: ""
        }
        Api.login(requestData, source).then(data => {
            this.handleLoginSuccess(data, remember);
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    loading: false, 
                    savingInfo: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    handleLoginSuccess(data, remember){
        if(remember){        
            Cookie.options.duration = '2000';
        }

        Cookie.write("access_token", data.access_token);
        Cookie.write("refresh_token", data.refresh_token);
        Cookie.write("expires_in", data.expires_in);
        localStorage.setItem("forember_userinfo", JSON.stringify(data.user));
        this.props.onSuccess(data.user);
    }
    handleForgotSubmit(){
        const isValid = this.validateForgotForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        const { email } = this.state;
        const requestData = {
            email: email,
        };
        this.setState({loading: true, cancelToken: source, showError: false});
        Api.forgot(requestData, source).then(data => {
            this.setState({requestCode: true, validated: false, loading: false});
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
    }
    handleResetPassword(){
        const isValid = this.validateResetForm(true,true);
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        const { email, code, password } = this.state;
        const requestData = {
            email: email,
            code: code,
            password: password
        };
        this.setState({loading: true, cancelToken: source, showError: false});
        Api.reset(requestData, source).then(data => {
            this.setState({
                loading: false,
                message: data.message
            }); 
        }).catch(err => {
            if(typeof(err) === "object" && err.hasOwnProperty("message")){
                this.setState({
                    loading: false, 
                    showError: true, 
                    cancelToken: null,
                    errorMessage: err.message
                });
            }
        });
    }
    validateForm(){
        let validation = {
            email: 'success',
            password: 'success',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(this.state.password.length <= 0){
            validation.password = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateForgotForm(){
        let validation = {
            email: 'success',
            password: '',
            passwordConfirm: '',
            code: '',
            isValid: true
        };
        var emailRegex = /\S+@\S+\.\S+/;
        if(this.state.email.length <= 0 || !emailRegex.test(this.state.email)){
            validation.email = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    validateResetForm(validatePassword = false, validatePasswordConfirm = false){
        let validation = {
            email: 'success',
            password: (validatePassword ? 'success': this.state.validation.password),
            passwordConfirm: (validatePasswordConfirm ? 'success': this.state.validation.passwordConfirm),
            code: '',
            isValid: true
        };
        if(this.state.code.length <= 3){
            validation.code = "error";
            validation.isValid = false;
        }

        const that = this;
        const check = PasswordMatch.check(this.state.password,function(requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength){
            that.setState({
              showPasswordErrors: true,
              requireLowerletter: requireLowerletter,
              requireUpperletter: requireUpperletter,
              requireNumber: requireNumber,
              requireSymbol: requireSymbol,
              requireLength: requireLength
            });
        });
        if(this.state.password.length <= 0 || check === false){
            if(validatePassword){
                validation.password = "error";
            }
            validation.isValid = false;
        }
        if(this.state.passwordConfirm.length <= 0 || this.state.password !== this.state.passwordConfirm){
            if(validatePasswordConfirm){
                validation.passwordConfirm = "error";
            }
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    handleForgotPassword(){
        this.setState({forgotPassword: true});
        this.props.history.push("/forgot");
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"sd-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className={"sd-error-message passwordCheck-notValid-customizable"}>
            <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
            <span className="checkPasswordText-lowerletter">{errorMessage}</span>
        </div>;
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    handleLoginSubmit(provider, profile){
        console.log(provider);
        console.log(profile);
        let accessToken = "";
        if(profile.hasOwnProperty("accessToken")){
            accessToken = profile.accessToken;
        }else{
            accessToken = profile.access_token;
        }

        const source = axios.CancelToken.source();
        const requestData = {
            grant_type: "password",
            client_id: Config.getApiClientId(),
            client_secret: Config.getApiClientSecret(),
            scope: "",
            provider: provider,
            email: profile.email,
            access_token: accessToken
        }
        Api.socialLogin(requestData, source).then(data => {
            this.handleLoginSuccess(data, true);
        }).catch(err => {
            if(typeof(err) === "object"){
                let errors = "";
                if(err.hasOwnProperty("message")){
                    errors = err.message;
                }
                if(err.hasOwnProperty("errors")){
                    errors = err.errors;
                }
                this.setState({
                    loading: false, 
                    savingInfo: false, 
                    showError: true, 
                    errorMessage: errors
                });
            }
        });
        this.setState({loading: true, showError: false, cancelToken: source});
    }
    render() {
        const { loading, validation, showError, forgotPassword, requestCode, password, passwordConfirm,
         requireLowerletter, requireUpperletter, requireNumber, requireSymbol, requireLength } = this.state;
         
        const showPasswordErrors = (validation.password === "success" || validation.password === "error");
        const REDIRECT_URI = window.location.href;

        return (
            <>
                <div className="">
                    <div className='sd-login-page-content'>
                        <div className="sd-form-section">
                            <div className='sd-login-form-section'>
                                <form className={"form"} onSubmit={this.handleSubmit} noValidate>
                                    <h1>
                                        {
                                            forgotPassword ?
                                                "Forgot Password"
                                            :
                                                "Sign In"
                                        }
                                    </h1>
                                    {
                                        forgotPassword === false ?
                                            <>
                                                <div className="buttons-group social-buttons">
                                                    <LoginSocialFacebook
                                                        appId={process.env.REACT_APP_FB_APP_ID || ''}
                                                        fieldsProfile={
                                                            'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                                                        }
                                                        redirect_uri={REDIRECT_URI}
                                                        onResolve={({ provider, data }: IResolveParams) => {
                                                            this.handleLoginSubmit(provider, data)
                                                        }}
                                                        onReject={err => {
                                                            console.log(err);
                                                        }}
                                                    >
                                                        <Button size={"lg"} className={"button facebook radius-8"}>
                                                            <Facebook />Sign-in with Facebook
                                                        </Button>
                                                    </LoginSocialFacebook>
                                                    
                                                    <LoginSocialGoogle
                                                        client_id={Config.getGoogleClientId()}
                                                        redirect_uri={REDIRECT_URI}
                                                        scope="openid profile email"
                                                        access_type="offline"
                                                        onResolve={({ provider, data }: IResolveParams) => {
                                                            this.handleLoginSubmit(provider, data)
                                                        }}
                                                        onReject={err => {
                                                            console.log(err);
                                                        }}
                                                    >
                                                        <Button size={"lg"} className={"button google radius-8"}>
                                                            <Google />Sign-in with Google
                                                        </Button>
                                                    </LoginSocialGoogle>
                                                </div>
                                                <div className="or-divider">
                                                    <p>OR</p>
                                                </div>
                                            </>
                                        :
                                            <></>
                                    }
                                    {
                                        showError ?
                                            this.renderErrorMessages()
                                        :
                                        <></>
                                    }
                                    <div className="form-inputs">
                                        {
                                            requestCode === false ?
                                                <div className="element-container">
                                                    <input 
                                                        className={validation.email === "error" ? 'error' : ''}
                                                        type="email" 
                                                        placeholder="Email Address"
                                                        onChange={(e) => this.handleChange(e,'email')}
                                                    />
                                                    {
                                                        validation.email === "error" ?
                                                            <Error />
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                            :
                                                <>
                                                    <p>We have sent a code by email. Enter it below to confirm your account.</p>
                                                    <input
                                                        success={validation.code === "success"}
                                                        error={validation.code === "error"}
                                                        id="input-code"
                                                        labelText="Verification Code"
                                                        inputProps={{
                                                            required: true,
                                                            onChange: (e) => this.handleChange(e,'code'),
                                                            name: "code",
                                                            type: "text"
                                                        }}                                    
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />
                                                    <input
                                                        success={validation.password === "success"}
                                                        error={validation.password === "error"}
                                                        id="input-password"
                                                        labelText="New Password"
                                                        inputProps={{
                                                            required: true,
                                                            onChange: (e) => this.handleChange(e,'password'),
                                                            name: "password",
                                                            type: "password",
                                                            value: password
                                                        }}                                    
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />
                                                    {
                                                        showPasswordErrors ?
                                                                <div>
                                                                    <div className={(requireLowerletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-lowerletter"}>
                                                                        <span aria-hidden="true" className="validation-error-symbol check-lowerletter">{requireLowerletter? '\u2713' : '\u2716' }</span>
                                                                        <span className="checkPasswordText-lowerletter">Password must contain a lower case letter</span>
                                                                    </div>
                                                                    <div className={(requireUpperletter?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-upperletter"}>
                                                                        <span aria-hidden="true" className="validation-error-symbol check-upperletter">{requireUpperletter? '\u2713' : '\u2716' }</span>
                                                                        <span className="checkPasswordText-upperletter">Password must contain an upper case letter</span>
                                                                    </div>
                                                                    <div className={(requireNumber?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-numbers"}>
                                                                        <span aria-hidden="true" className="validation-error-symbol check-symbols">{requireNumber? '\u2713' : '\u2716' }</span>
                                                                        <span className="checkPasswordText-symbols">Password must contain a number</span>
                                                                    </div>
                                                                    <div className={(requireSymbol?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-symbols"}>
                                                                        <span aria-hidden="true" className="validation-error-symbol check-numbers">{requireSymbol? '\u2713' : '\u2716' }</span>
                                                                        <span className="checkPasswordText-numbers">Password must contain a special character</span>
                                                                    </div>
                                                                    <div className={(requireLength?"passwordCheck-valid-customizable":"passwordCheck-notValid-customizable")+" checkPassword-length"}>
                                                                        <span aria-hidden="true" className="validation-error-symbol check-length">{requireLength? '\u2713' : '\u2716' }</span>
                                                                        <span className="checkPasswordText-length">Password must contain at least 8 characters</span>
                                                                    </div>
                                                                </div>
                                                        :
                                                        <></>
                                                    }
                                                    <input
                                                        success={validation.passwordConfirm === "success"}
                                                        error={validation.passwordConfirm === "error"}
                                                        id="input-passwordConfirm"
                                                        labelText="New Password Again"
                                                        inputProps={{
                                                            required: true,
                                                            onChange: (e) => this.handleChange(e,'passwordConfirm'),
                                                            name: "passwordConfirm",
                                                            type: "password",
                                                            value: passwordConfirm
                                                        }}                                    
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                    />
                                                </>
                                        }
                                        {
                                            forgotPassword === false ?
                                                <div className="element-container">
                                                    <input 
                                                        className={validation.password === "error" ? 'error' : ''}
                                                        type="password" 
                                                        placeholder="Password" 
                                                        onChange={(e) => this.handleChange(e,'password')}
                                                    />
                                                    {
                                                        validation.password === "error" ?
                                                            <Error />
                                                        :
                                                            <></>
                                                    }
                                                </div>
                                            :
                                                <></>
                                        }
                                    </div>
                                    {
                                        forgotPassword === false ?
                                            <div className="switch-forgot">
                                                <div className="switch-button">
                                                    <FormControlLabel 
                                                        control={
                                                            <Switch 
                                                                checked={this.state.remember}
                                                                onChange={(e) => this.handleCheckbox(e, 'remember')}
                                                            />
                                                        } 
                                                        label="Remember me" 
                                                    />
                                                </div>
                                                <p className={"link"} onClick={this.handleForgotPassword}>Forget Password?</p>
                                            </div>
                                        :
                                            <></>
                                    }
                                    <div className="buttons-group">
                                        {
                                            loading ?
                                                <LoaderComponent />
                                            : forgotPassword === false ?
                                                <>
                                                    <Button size={"lg"} type="submit" className={"button signin radius-8"}>Sign in</Button>
                                                    <Button size={"lg"} onClick={() => this.props.onSignup()} className={"button transparent radius-8"}>
                                                        Create Account
                                                    </Button>
                                                </>
                                            :
                                                requestCode === true ?
                                                    <Button size={"lg"} type="submit" className="button">Reset Password</Button>
                                                :
                                                    <Button size={"lg"} type="submit" className="button">Help me!</Button>
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};

export default connect(mapStateToProps)(LoginModal);
