import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import { Close, KeyboardVoice, CloudUpload } from "@mui/icons-material";

import "../../../assets/scss/audioModalStyle.scss";

class AudioModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }
    render() {
        return (
            <>
                <Dialog
                    className="sd-video-modal"
                    open={this.props.open}
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className="sd-audio-modal-icons">
                            <div className="sd-audio-icon">
                                <div className="sd-icon" onClick={()=> this.props.onRecord()}>
                                    <KeyboardVoice />
                                </div>
                                <p>Record Audio</p>
                            </div>
                            <p>OR</p>
                            <div className="sd-video-icon">
                                <div className="sd-icon" onClick={()=> this.props.onUpload()}>
                                    <CloudUpload />
                                </div>
                                <p>Upload Audio</p>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }
}
export default AudioModal;