import React from 'react'
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import UserHome from "../pages/user/Home";
import UserHug from "../pages/user/Hug";
import PublicHug from "../pages/HugPublic";
import Hug from "../pages/Hug";
import UserSettings from "../pages/user/Settings";
import CreateHug from "../pages/user/CreateHug";
import PrivacyPolicy from "../pages/PrivayPolicy";
import Terms from "../pages/Terms";
import EmailUnsubscribe from "../pages/user/EmailUnsubscribe";
import Invite from "../pages/user/Invite";

const RouterComponent = class extends React.Component {
    render() {
        const { history } = this.props;
        return (
            <div id="global_wrapper">
                <div id="global_content">
                    <Routes location={history.location}>
                        <Route path="/" exact={true} element={<Home history={history} />} />
                        <Route path="/about-us" exact={true} element={<AboutUs history={history} />} />
                        <Route path="/contact-us" exact={true} element={<ContactUs history={history} />} />
                        <Route path="/login" exact={true} element={<Login history={history} forgot={false} />} />
                        <Route path="/forgot" exact={true} element={<Login history={history} forgot={true} />} />
                        <Route path="/signup" exact={true} element={<Signup history={history} />} />
                        <Route path="/home" exact={true} element={<UserHome history={history} />} />
                        <Route path="/hug/:id" exact={true} element={<UserHug history={history} />} />
                        <Route path="/hug/public/:id" exact={true} element={<PublicHug history={history} />} />
                        <Route path="/hug/:idHash/:dateHash" exact={true} element={<Hug history={history} />} />
                        <Route path="/create-hug" exact={true} element={<CreateHug history={history} />} />
                        <Route path="/hug/edit/:id" exact={true} element={<CreateHug history={history} />} />
                        <Route path="/Settings" exact={true} element={<UserSettings history={history} />} />
                        <Route path="/privacy-policy" exact={true} element={<PrivacyPolicy history={history} />} />
                        <Route path="/terms" exact={true} element={<Terms history={history} />} />
                        <Route path="/unsubscribe/:hash" exact={true} element={<EmailUnsubscribe history={history} />} />
                        <Route path="/invite" exact={true} element={<Invite history={history} />} />
                    </Routes>
                </div>
            </div>
        )
    }
}

export default RouterComponent;
