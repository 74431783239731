import React from 'react';
import { CircularProgress } from '@mui/material';

class Loader extends React.Component {
    render(){
        return (
            <div className={"sd_loader"}>
                <CircularProgress size={30} color="inherit"/>
            </div>
        )
    }
}

export default Loader;