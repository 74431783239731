import React, { Component } from "react";
import { Stepper, Step, StepLabel, Button } from '@mui/material';
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import LoaderComponent from '../../components/Loader'
import AuthApi from '../../../assets/js/utils/Auth';
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import { matchPath } from 'react-router';
import DeleteHugModal from "../../components/Modal/DeleteHugModal";
import SignupModal from "../../components/Modal/SignupModal";
import { helper } from '../../../assets/js/utils/Element';

import "../../../assets/scss/createHugStyle.scss";

class CreateHug extends Component {
    constructor(props) {
        super(props);

        let match = matchPath({
            path: "/hug/edit/:id",
        }, window.location.pathname);
        let id = null;
        if(match !== null && match.params.hasOwnProperty("id")){
            id = match.params.id;
        }
        this.state = {
            id: id,
            hugResponse: null,
            activeStep: 0,
            stepStates: {
                step1: null,
                step2: null,
                step3: null,
                step4: null,
                step5: null,
                step6: null
            },
            saving: false,
            deleteModal: false,
            deleting: false,
            signupModal: false,
            signupSuccessFlow: false,
            deleted: false,
            isHugDeleted: false,
        };

        this.signupFlow = false;
        const hugState = localStorage.getItem("forember_hug");
        if(hugState !== null && typeof(hugState) === "string"){
            const json = JSON.parse(hugState);
            json.signupModal = false;
            this.state = json;
            localStorage.removeItem("forember_hug");
            this.signupFlow = true;
        }

        this.stepRef = React.createRef();
    }
    static getDerivedStateFromProps(props, state) {
        const hugState = localStorage.getItem("forember_hug");
        if(hugState !== null && typeof(hugState) === "string"){
            const json = JSON.parse(hugState);
            json.signupModal = false;
            json.signupSuccessFlow = true;
            localStorage.removeItem("forember_hug");
            return json;
        }
        return null; // No change to state
    }
    componentDidUpdate(){
        if(this.state.signupSuccessFlow){
            this.onHugCreate();
        }
    }
    componentDidMount(){
        // const { authorized } = this.props.state;
        // if(!authorized && !AuthApi.hasAccessToken()){
        //     const location = this.props.history.location;

        //     const loginRequired = "/login?return="+encodeURIComponent(location.pathname+location.search);
        //     this.props.history.push(loginRequired);
        //     return;
        // }

        if(this.state.id !== null){
            this.getUserHug();
        }
        if(this.signupFlow){
            this.onHugCreate();
        }

        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    getUserHug(){
        const { id } = this.state;
        let requestData = {
            id: id,
        }
        const source = axios.CancelToken.source();
        Api.getUserHug(requestData, source).then(data => {
            this.setState({
                hugResponse: data,
                isHugDeleted: data.delete,
                loadingHug: false,
                stepStates: {
                    ...this.state.stepStates,
                    step1: {
                        recipients: data.recipients,
                        notes: data.notes,
                        relationshipOptions: this.getRelationshipOptions(data),
                        noteLimit: 300,
                        noteLength: 0,
                    },
                    step2: {
                        uploads: this.getAudioVideos(data.attachments),
                        disabledAudio: this.isButtonDisabled(data.attachments, 'audio'),
                        disabledVideo: this.isButtonDisabled(data.attachments, 'video'),
                        fileChecked: this.getFileChecked(data.attachments),
                        playerData: null
                    },
                    step3: {
                        photos: this.getPhotos(data.attachments),
                        limit: 5
                    },
                    step4: {
                        edit: true
                    },
                    step5: {
                        edit: true
                    },
                    step6: {
                        draft: true
                    }
                }
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                loadingHug: false,
            });
        });

        this.setState({
            loadingHug: true,
        })
    }
    isButtonDisabled(attachments, type){
        let disabled = true;
        attachments.map((attachment) => {
            if(attachment.type === type){
                disabled = false;
            }

            return null;
        });

        return disabled;
    }
    getRelationshipOptions(data){
        let relationshipOptions = helper.relationshipOptions();
        let relationshipArray = [];
        relationshipOptions.map(relationship => {
            relationshipArray.push(relationship.key);

            return null;
        });

        data.recipients.map(recipient => {
            if(!relationshipArray.includes(recipient.relationship)){
                relationshipOptions.push({
                    key: recipient.relationship,
                    value: recipient.relationship
                });
            }

            return null;
        });

        return relationshipOptions;
    }
    getFileChecked(attachments){
        let files = this.getAudioVideos(attachments);

        let fileChecked = null;
        files.map((file) => {
            fileChecked = file.id;

            return null;
        });

        return fileChecked;
    }
    getAudioVideos(attachments){
        let attachmentsArr = [];
        attachments.map((attachment) => {
            if(attachment.type === 'audio' || attachment.type === 'video'){
                attachmentsArr.push(attachment);
            }

            return null;
        });

        return attachmentsArr;
    }
    getPhotos(attachments){
        let attachmentsArr = [];
        attachments.map((attachment) => {
            if(attachment.type === 'photo'){
                attachmentsArr.push(attachment);
            }

            return null;
        });

        return attachmentsArr;
    }
    getSteps() {
        return [
            'Hug Recipient',
            'Choose Audio',
            'Choose Photos',
            'Create Forever Hug',
            ' '
        ];
    }
    getStepContent(step) {
        const { history } = this.props;
        const { stepStates, id, isHugDeleted } = this.state;
        const { authorized } = this.props.state;

        let content = null;
        switch (step) {
            case 0:
                content = <Step1 history={history} ref={this.stepRef} state={stepStates.step1} editStep={id !== null ? true : false} onDeleteModal={(status) => this.onDeleteModal(status)} isHugDeleted={isHugDeleted} />
                break;
            case 1:
                content = <Step2 history={history} ref={this.stepRef} state={stepStates.step2} handleStateUpdate={() => this.handleStateUpdate()} />
                break;
            case 2:
                content = <Step3 history={history} ref={this.stepRef} state={stepStates.step3} handleStateUpdate={() => this.handleStateUpdate()} />
                break;
            case 3:
                content = <Step4 history={history} ref={this.stepRef} state={stepStates.step4} authorized={authorized}/>
                break;
            case 4:
                content = <Step5 history={history} ref={this.stepRef} state={stepStates.step5} />
                break;
            case 5:
                content = <Step6 history={history} ref={this.stepRef} state={stepStates.step6} />
                break;
            default:
                break;
        }
        return content;
    }
    handleNext = () => {
        const { stepStates, activeStep } = this.state;
        if(!this.stepRef.current.isValid()){
            return;
        }
        this.setState({
            activeStep: (activeStep + 1),
            stepStates: {
                ...stepStates,
                ["step"+(activeStep + 1)]: this.stepRef.current.state
            },
        },() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        })
    }
    handleBack = () => {
        const { activeStep, stepStates } = this.state;
        this.setState({
            activeStep: activeStep - 1,
            stepStates: {
                ...stepStates,
                ["step"+(activeStep + 1)]: this.stepRef.current.state
            }
        },() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        });
    }
    handleStep(step){
        const { activeStep, stepStates } = this.state;
        
        if(!this.isStepComplete(step)){
            return;
        }
        
        this.setState({
            activeStep: step,
            stepStates: {
                ...stepStates,
                ["step"+(activeStep + 1)]: this.stepRef.current.state
            }
        },() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        });
    }
    handleStateUpdate(){
        const { stepStates, activeStep } = this.state;
        this.setState({
            stepStates: {
                ...stepStates,
                ["step"+(activeStep + 1)]: this.stepRef.current.state
            },
        })
    }
    onHugCreate(draft = false, user = null){
        this.signupFlow = false;
        const { authorized, hugs } = this.props.state;
        if(!authorized && !AuthApi.hasAccessToken()){
            this.setState({
                signupModal: true,
                signupSuccessFlow: false
            });
            return;
        }
        const { id, activeStep, stepStates } = this.state;
        const { step1, step2, step3 } = this.state.stepStates;
        let requestData = {
            notes: step1.notes,
        };
        let recipients = [];
        step1.recipients.map((recipient) => {
            recipients.push({
                first_name: recipient.first_name,
                email: recipient.email,
                relationship: recipient.relationship,
                hug_email: recipient.hug_email,
                hug_email_date: recipient.hug_email_date,
                hug_view: recipient.hug_view,
                hug_creator_email: recipient.hug_creator_email
            });
            return null;
        });
        let mediaFiles = [];
        if(step2 !== null){
            step2.uploads.map((file) => {
                mediaFiles.push(file.id);
                return null;
            });
            requestData['fileChecked'] = step2.fileChecked;
        }
        let photos = [];
        if(step3 !== null){
            step3.photos.map((photo) => {
                photos.push(photo.id);
                return null;
            });
        }
        
        requestData['recipients'] = recipients;
        requestData['media_files'] = mediaFiles;
        requestData['photos'] = photos;
        requestData['id'] = id;
        requestData['draft'] = draft;
        this.setState({
            saving: true,
            signupSuccessFlow: false
        });
        this.props.dispatch({type: 'UPDATE_STATE',state: {hugs: []}});
        const source = axios.CancelToken.source();
        Api.createHug(requestData, source).then(data => {
            if(draft){
                this.setState({
                    id: data.id,
                    saving: false,
                    activeStep: 5,
                    stepStates: {
                        ...stepStates,
                        step6: this.state.stepStates.step6
                    }
                });
                if(user !== null){
                    this.props.dispatch({type: 'UPDATE_STATE',state: {
                        authorized: true,
                        user: user
                    }});
                }
            }else{
                this.setState({
                    id: data.id,
                    activeStep: activeStep + 1,
                    stepStates: {
                        ...stepStates,
                        ["step"+(activeStep + 1)]: this.stepRef.current.state
                    },
                    saving: false
                });

                hugs.push(data.id);
                if(user !== null){
                    this.props.dispatch({type: 'UPDATE_STATE',state: {
                        hugs: hugs,
                        authorized: true,
                        user: user
                    }});
                }else{
                    this.props.dispatch({type: 'UPDATE_STATE',state: {hugs: hugs}});
                }
            }
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                saving: false,
            })
        });
    }
    onDeleteModal(status = false, redirect = false){
        let state = {
            deleteModal: status,
            deleted: false,
        }

        if(redirect){
            // this.props.history.push("/home");
            state['isHugDeleted'] = true;
        }

        this.setState(state);
    }
    onDeleteModalSuccess(){
        const { id, isHugDeleted } = this.state;
        if(id === null){
            return;
        }
        this.setState({
            deleting: true
        });

        if(isHugDeleted){
            const source = axios.CancelToken.source();
            Api.cancelDeleteHug(id, source).then((data) => {
                this.setState({
                    isHugDeleted: false,
                    deleted: false,
                    deleting: false,
                });            
            }).catch(err => {
                this.setState({
                    showError: true, 
                    errorMessage: err.message,
                    deleting: false,
                });
            });

            return;
        }

        Api.deleteHug(id).then((data) => {
            this.setState({
                deleted: true,
                deleting: false,
            });            
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                deleting: false,
            });
        });
    }
    onSignupCancel(){
        this.setState({
            signupModal: false
        });
    }
    onSignupSuccess(user){     
        this.setState({
            signupModal: false
        }, () => {
            const json = JSON.stringify(this.state);
            localStorage.setItem("forember_hug", json);
            this.props.dispatch({
                type: "LOGIN",
                state: {
                    authorized: true,
                    user: user
                }
            });
        });
    }
    isStepComplete(step){
        const { stepStates } = this.state;

        if(step === 1){
            if(stepStates.step2 === null || stepStates.step2.uploads.length <= 0){
                return false;
            }
        }else if(step === 2 || step === 3){
            if(stepStates.step3 === null){
                return false;
            }
        }else if(step === 4){
            return false;
        }

        return true;
    }
    renderErrorMessages(){
        const { errorMessage } = this.state;
        if(typeof(errorMessage) === "object"){
            let errorMessages = [];
            let key = 0;
            for(const attrib in errorMessage){
                const message = errorMessage[attrib];
                errorMessages.push(<div key={key} className={"sd-error-message passwordCheck-notValid-customizable"}>
                    <span aria-hidden="true" className="validation-error-symbol check-lowerletter">&#x2716;</span>
                    <span className="checkPasswordText-lowerletter">{message}</span>
                </div>);
                key++;
            }
            return errorMessages;
        }
        return <div className="sd-section-bg">
                    <div className="container">
                        <div className="sd-hug-not-found">
                            <span>{errorMessage}</span>
                        </div>
                    </div>
                </div>;
    }
    render() {
        const { activeStep, saving, loadingHug, id, deleteModal, deleting, signupModal, deleted, isHugDeleted, hugResponse, showError } = this.state;
        const steps = this.getSteps();
        const { user } = this.props.state;

        return (
            <>
                {
                    loadingHug ?
                        <div className="sd-hug-loader">
                            <LoaderComponent />
                        </div>
                    : hugResponse !== null && hugResponse.owner_id !== user.id ?
                        <div className="sd-section-bg">
                            <div className="container">
                                <div className="sd-hug-not-found">
                                    <span>You are not authorized to edit this hug.</span>
                                </div>
                            </div>
                        </div>
                    : showError ?
                        <div className="sd-error-messages">
                            { this.renderErrorMessages() }
                        </div>
                    :
                        <>
                            <div className="sd-create-hug-content">
                                <div className="sd-create-hug-steps">
                                    <div className="container">
                                        <Stepper activeStep={activeStep} alternativeLabel>
                                            {
                                                steps.map((label, key) => (
                                                    <Step className={this.isStepComplete(key) ? "sd-step-completed" : ""} key={label} onClick={() => this.handleStep(key)}>
                                                        <StepLabel>{label}</StepLabel>
                                                    </Step>
                                                ))
                                            }
                                        </Stepper>
                                    </div>
                                </div>
                                <div className={"sd-create-hug-steps-section "+ (activeStep === 3 ? 'min-height' : 'min-height')}>
                                    <div className="container">
                                        <div className="sd-create-hug-steps-content">
                                            {
                                                this.getStepContent(activeStep)
                                            }
                                        </div>
                                        {
                                            activeStep < 4 ?
                                                <div className={"sd-create-hug-footer-btns "+ (activeStep === 3 ? 'center' : '')}>
                                                    {
                                                        activeStep === 3 ?
                                                            saving ?
                                                                <Button
                                                                    className={"button radius-8"}
                                                                >
                                                                    <LoaderComponent />
                                                                </Button>
                                                            :
                                                                <Button
                                                                    onClick={() => this.onHugCreate()}
                                                                    className={"button radius-8"}
                                                                >
                                                                    { id !== null ? 'Update' : 'Create' }  Forever Hug
                                                                </Button>
                                                        :
                                                            <>
                                                                {
                                                                    activeStep > 0?
                                                                        <Button
                                                                            onClick={this.handleBack}
                                                                            className={"button radius-8"}
                                                                        >
                                                                            Back
                                                                        </Button>
                                                                    :
                                                                        <></>
                                                                }
                                                                <div className="buttons-group">
                                                                    {
                                                                        activeStep > 0 ?
                                                                            saving ?
                                                                                <Button
                                                                                    className={"button radius-8"}
                                                                                >
                                                                                    <LoaderComponent />
                                                                                </Button>
                                                                            :
                                                                                <Button
                                                                                    onClick={() => this.onHugCreate(true)}
                                                                                    className={"button transparent radius-8"}
                                                                                >
                                                                                    Save for later
                                                                                </Button>
                                                                        :
                                                                            <></>
                                                                    }
                                                                    <Button
                                                                        onClick={this.handleNext}
                                                                        className={"button radius-8"}
                                                                    >
                                                                        Next
                                                                    </Button>
                                                                </div>
                                                            </>
                                                    }
                                                </div>
                                            :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                deleteModal ?
                                    <DeleteHugModal
                                        open={deleteModal}
                                        saving={deleting} 
                                        deleted={deleted}
                                        onClose={(status, redirect) => this.onDeleteModal(status, redirect)} 
                                        onSuccess={() => this.onDeleteModalSuccess()}
                                        isHugDeleted={isHugDeleted}
                                    />
                                :
                                    <></>
                            }
                        </>
                }
                {
                    signupModal ?
                        <SignupModal
                            open={signupModal}
                            onCancel={() => this.onSignupCancel()}
                            onSuccess={(user) => this.onSignupSuccess(user)}
                            history={this.props.history}
                        />
                    :
                    null
                }
            </>
        );
    }
};

export default connect(mapStateToProps)(CreateHug);