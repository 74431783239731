
import React from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/reducers/rootReducer";
import Api from "../../assets/js/utils/Api";
import axios from "axios";
import LoaderComponent from '../components/Loader'
import { Link as RouterLink } from "react-router-dom";

import "../../assets/scss/progressbarStyle.scss";

class Progressbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    componentDidMount(){
        this.generateHugs();

        if(this.props.generate === false){
            setTimeout(() => {
                this.generateHugs();
            }, 10000);
        }
    }
    getHugIds(hugs){
        let hugIds = '';
        hugs.map((hug) => {
            hugIds += hug+',';

            return null;
        });

        return hugIds;
    }
    generateHugs(){
        const { hugs, hugsGenerated } = this.props.state;

        this.setState({
            loading: true
        })
        const source = axios.CancelToken.source();

        let hugIds = this.getHugIds(hugs);
        let requestData = {
            hugIds: hugIds,
            generate: this.props.generate ? 1 : 0
        };

        if(!this.props.generate && !hugsGenerated){
            return;
        }
        Api.generateHugs(requestData, source).then(data => {
            this.setState({
                loading: false
            })
            
            let stateObj = {};
            stateObj['hugsGenerated'] = true;
            if(!this.props.generate){
                stateObj['hugs'] = [];
                stateObj['hugsGenerated'] = false;
            }
            this.props.dispatch({type: 'UPDATE_STATE',state: stateObj});
            this.props.onGenerateHugs(data.hugs);
        }).catch(err => {
            // this.setState({
            //     showError: true, 
            //     errorMessage: err.message,
            //     saving: false,
            // })
        });
    }
    getHugItem(hug, key){
        return (
            <div className="sd-hug-item hug-processing" key={key}>
                <RouterLink className={'nocursor'} to={"/home"} onClick={''} >
                    <div className='sd-hug-content'>
                        <div className={"sd-hug-pending-content "+this.props.layout+"-view"}>
                            <div className="loader">
                                <LoaderComponent />
                            </div>
                            <p>Pending</p>
                        </div>
                    </div>
                </RouterLink>
            </div>
        )
    }
    render() {
        const { loading } = this.state;
        const { hugs } = this.props.state;
        const { hug, key, layout } = this.props;
        return (
            <>
                {
                    loading ? 
                        layout === 'tile' ?
                            hugs.map((hug, key) => {
                                return(
                                    this.getHugItem(hug, key)
                                )
                            })
                        : layout === 'list' ?
                            this.getHugItem(hug, key)
                        :
                            <></>
                    :
                        <></>
                }
            </>
        );
    }
}

Progressbar.defaultProps = {
    generate: false
}

export default connect(mapStateToProps)(Progressbar);
