
import React from "react";
import Header from "./Header/Header.jsx";
import HeaderLinks from "./Header/HeaderLinks.jsx";
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/reducers/rootReducer";

class NavbarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.history = this.props.history;
        let color = "white";
        if (this.props.isTransparentHeader === true) {
            color = "transparent";
        }
        this.state = {
            open: false,
            color: color,
            feedbackMode: false,
        };

        this.updateDrawer = this.updateDrawer.bind(this);
    }

    updateDrawer(open) {
        this.setState({ open: open });
    }
    render() {
        const { color } = this.state;
        return (
            <Header
                brand={process.env.REACT_APP_MENU_TITLE}
                links={
                    <HeaderLinks
                        dropdownHoverColor="white"
                        color={color}
                        onClose={() => this.updateDrawer(false)}
                        history={this.history}
                        desktop={false}
                    />
                }
                desktopLinks={
                    <HeaderLinks
                        dropdownHoverColor="white"
                        color={color}
                        desktop={true}
                        history={this.history}
                    />
                }
                changeColorOnScroll={{
                    height: 100,
                    color: "white",
                }}
                color={color}
                bottomBarColor="white"
                store={this.store}
                history={this.history}
                open={this.state.open}
                updateDrawer={this.updateDrawer}
            />
        );
    }
}

export default connect(mapStateToProps)(NavbarComponent);
