import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, DialogActions } from "@mui/material";
import { Close, KeyboardVoice, /*PauseCircle, PlayCircle, RadioButtonChecked,*/ } from "@mui/icons-material";
import { helper } from "../../../assets/js/utils/Element";
import ConfirmationModal from "./ConfirmationModal";
import { Link } from "react-router-dom";

import "../../../assets/scss/recordAudioModalStyle.scss";

class RecordAudioModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            time: 0,
            limit: 60,
            isRecording: false,
            isPaused: false,
            blob: null,
            cancelModal: false,
            countdownStart: false,
            countdown: 4
        };

        this.recorder = null;
        this.timer = null;
        this.countdownInterval = null;
        this.audioPlayer = React.createRef();
    }
    componentDidMount(){
        // this.setupRecorder();
    }
    setupRecorder(start = false){
        const that = this;
        window.navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                that.setState({
                    isRecording: true,
                    isPaused: false,
                    time: 0
                })

                let options = {};
                if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
                    options = {mimeType: 'video/webm'};
                } else  if (MediaRecorder.isTypeSupported('video/webm')) {
                    options = {mimeType: 'video/webm'};
                } else if (MediaRecorder.isTypeSupported('video/mp4')) {
                    options = {mimeType: 'video/mp4', videoBitsPerSecond : 100000};
                }
                
                that.recorder = new MediaRecorder(stream, options);
                // that.recorder = new MediaRecorder(stream);

                that.recorder.addEventListener("dataavailable", (event) => {
                    if(that.resetRecorder){
                        that.resetRecorder = false;
                        return;
                    }
                    let mimeType = that.recorder.mimeType;
                    
                    let audioBlob = new Blob([event.data], { type: mimeType });
                    that.setState({
                        blob: audioBlob
                    }, () => {
                        const { time, blob } = this.state;
                        that.props.onUpload(blob, time);
                    });
                    that.recorder.stream.getTracks().forEach((t) => t.stop());
                    that.recorder = null;
                });
                if(start){
                    that.recorder.start();
                    that.startTimer();
                }
            }).catch(
                (err) => console.log(err)
            );
    }
    startRecording(){
        if(this.recorder !== null && this.recorder.state === "recording"){
            this.initRecording();

            return;
        }
        this.countdownInterval = setInterval(() => {
            this.setState((prevState) => ({
                countdownStart: true,
                countdown: prevState.countdown - 1,
            }), () => {
                if (this.state.countdown === 1) {
                    clearInterval(this.countdownInterval);

                    this.initRecording();
                }
            });
        }, 1000);
    }
    initRecording(){
        this.resetRecorder = false;
        if(this.recorder !== null && (this.recorder.state === "recording" || this.recorder.state === "paused")){
            if(this.recorder.state === "paused"){
                this.recorder?.resume();
                this.startTimer();
            }else{
                this.recorder?.pause();
                this.stopTimer();
                this.setState({
                    isRecording: false,
                    isPaused: false,
                    countdown: 4
                })
            }
            
            return;
        }
        if(this.recorder === null){
            this.setupRecorder(true);
            return;
        }
        this.recorder.start();
        this.startTimer();  
    }
    startTimer(){
        this.timer = setInterval(() => {
            this.setState({
                time: this.state.time + 1,
                isRecording: true,
                countdownStart: false,
            }, () => {
                if(this.state.time >= this.state.limit){
                    this.stopRecording();
                }
            });
        }, 1000);
    }
    stopTimer(){
        if(this.timer !== null){
            clearInterval(this.timer);
        }
        if(this.countdownInterval !== null){
            clearInterval(this.countdownInterval);
        }
    }
    resetRecording(){
        this.resetRecorder = true;
        this.recorder?.stop();
        this.stopTimer();
        this.setState({
            isRecording: false,
            isPaused: false,
            time: 0,
            blob: null,
            countdownStart: false,
            countdown: 4
        })
    }
    stopRecording(){
        if(this.state.time <= 0){
            return;
        }
        this.recorder?.stop();
        this.stopTimer();
        this.setState({
            isRecording: false,
            isPaused: false,
            countdown: 4
        });
    }
    playRecording(){
        const { blob } = this.state;
        if(blob === null || this.audioPlayer.current === null){
            return;
        }
        this.audioPlayer.current.play();
    }
    onCancelModal(status = false){
        this.setState({
            cancelModal: status
        })
    }
    render() {
        const { time, limit, cancelModal, isRecording, countdownStart, countdown } = this.state;
        return (
            <>
                <Dialog
                    className="sd-record-audio-modal"
                    open={this.props.open}
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.onCancelModal(true)}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className="sd-record-audio-content">
                            {/* <KeyboardVoice /> */}
                            <div className="sd-record-audio-player">
                                <div className="sd-audio-player-bar"></div>
                                <div className="sd-audio-player-circle" style={{left: (time/limit*100)+"%"}}></div>
                                <div className="sd-audio-time">
                                    <p>{helper.toHHMMSS(time)}</p>
                                    <p>{helper.toHHMMSS(limit)}</p>
                                </div>
                            </div>
                            {/* {
                                blob !== null ?
                                    <audio
                                        ref={this.audioPlayer}
                                        controls
                                        src={window.URL.createObjectURL(blob)}
                                        style={{display: "none"}}
                                    ></audio>
                                :
                                null
                            } */}
                        </div>
                    </DialogContent>
                    <DialogActions
                        className={"modal-footer"}
                    >
                        {/* <Button className="button radius-8" onClick={() => this.props.onClose()}>Cancel</Button> */}
                        <div className="sd-audio-player-actions">
                            <div className="cancel-icon" title={"Cancel"}>
                                <Link onClick={() => this.resetRecording()}>Cancel</Link>
                            </div>
                            <div className={"mic-icon"+(isRecording && !countdownStart ? ' recording' : '')} title={"Record/Pause"}>
                                {
                                    countdownStart ?
                                        <span className="record-button">{countdown}</span>
                                    :
                                        <KeyboardVoice className="record-button" onClick={() => this.startRecording()} />
                                }
                            </div>
                            {/* <PlayCircle onClick={() => this.playRecording()} /> */}
                            <div className="accept-icon" title={"Accept"}>
                                <Link onClick={() => this.stopRecording()}>Save</Link>
                            </div>
                        </div>
                        {/* <Button className="button radius-8" disabled={(blob === null)} onClick={() => this.props.onUpload(blob, time)}>Upload</Button> */}
                    </DialogActions> 
                </Dialog>
                {
                    <ConfirmationModal
                        open={cancelModal}
                        onClose={() => this.onCancelModal(false)} 
                        onSuccess={() => this.props.onClose()}
                        title="Are you sure you want to cancel?"
                    />
                }
            </>
        )
    }
}
export default RecordAudioModal;