import React, { Component } from "react";
import { Link as RouterLink } from "react-router-dom";

import "../../assets/scss/privacyTermsStyle.scss";

class PrivacyPolicy extends Component {
    componentDidMount(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    render() {
        return (
            <>
                <div className="container">
                    <div className='sd-terms-privacy-container'>
                        <h2 className="two-one-color">Privacy Statement</h2>
                        <h3>Welcome to the Forember Privacy Policy</h3>
                        <h3>Last Modified:  March 2023</h3>
                        <p>Your privacy is important to us. This privacy policy governs how the Forember website <RouterLink to="/" className="not-blue" onClick={() => this.onLinkClick("/")}>www.forember.com</RouterLink>  (the "Website") and associated applications/software owned and operated by Forember LLC. ("we," "us," "our"), collects, uses, maintains, and discloses Personally Identifiable Information collected from its users ("visitor," "subscriber," "you," "your," "their" etc. each a "user"). We respect your privacy and are committed to adhering to federal and state privacy laws and industry guidelines to protect you and the personally identifiable information you disclose.  </p>
                        <p>The purpose of this privacy policy is to assist you in understanding the purpose for which we collect, use, disclose and process the personal information you have provided, or we collect from you. We are devoted to protecting your privacy. Therefore, please take a moment to check our privacy practices. We will make all attempts to protect all information when you use our website or any of its services. However, by interacting or submitting any personal information or any other information to us for any events, it's deemed your full consent to permit us to collect, use and disclose such Personal Information and Other Information per this privacy policy. </p>
                        <p>Please do not use the website if you disagree with this Privacy Policy. We reserve the right to make changes to this privacy policy. If we make a material change to this Privacy Policy, we will notify you by posting the changes in this Privacy Policy. Therefore, please check back occasionally to ensure you are aware of these changes. Your continued use of the service will signify your acceptance of these changes.</p>
                        <h4>INFORMATION WE MAY COLLECT FROM YOU</h4>
                        <p>For the purpose of this privacy policy, Personally Identifiable Information (PII) means any information relating to an identified or identifiable individual, which may include but is not limited to, your full name, email, phone number, address, city, state, language, date of birth, country, zip code, (the "personal information" or "personal data"). While using our website and its services, we may ask you to provide us with certain personal information, or we may automatically obtain from you that can be used to contact or identify you. In addition, our third-party service providers and we may collect the following Personal Information from you from various sources described below.</p>
                        <h4 className="underline">User-Provided Information</h4>
                        <ul>
                            <li><b className="underline">Registration or Sign Up.</b> You may not be required to sign up or create an account with us to visit our website and peruse its general contents. But to use any of our services including paid services, you may need to sign up or register on our website through a custom sign-up form, which may require you to provide certain information such as your Name, Address, Email Address, Phone number, Password, etc. Upon registration or sign-up, we may send you an email or phone verification code to verify your account and access your member profile page. We hereby disclaim, and you hereby release us from any and all liability arising from any unauthorized registration and from any related claims, losses, or damages allegedly arising from or relating to any unauthorized user registration and/or collection of personal information in connection with an unauthorized registration. </li>
                            <li><b className="underline">O-Authorization Registration System:</b> We have an optional o-authorization registration option using "plug-in" tools or apps like Facebook, Google, etc. Those tools may allow an automatic account registration with our website using your Google or Facebook etc. account. They will directly connect with those tools or app servers through your browser or device. If you register with us using the login details of such tools or apps, you are also subject to the privacy policy of such tools or apps. We use the o-authorization protocol to enable us to collect your required personal information without collecting your password.</li>
                            <li><b className="underline">Profile or Account Information:</b> To use certain features within our website and its services, we may also ask you to complete your profile with us by providing certain personal information in your Forember account page, which may include your full name, email address, Social Media profile picture, phone number, mailing address, (including but not limited to the city, state, country, zip code), date of birth and date of death for the deceased person in connection with certain features of the Services, Govt. Issued ID, gender, social media profiles/pages, etc. If you have any questions or concerns regarding this  personal information collection process, please contact us at <RouterLink to="mailto:admin@forember.com ">admin@forember.com</RouterLink></li>
                            <li><b className="underline">Payment Information:</b> We don't locally process any payments, and we don't collect any payment information by us or transfer it through our website. We may use third-party services for payment processing (e.g., payment processors). We are using "Stripe" secure gateway system through our Site and App as our payment getaway, and no payment data will be collected by us. The privacy policy of your payment information will be conducted by the privacy policy of stripe, which can be viewed at <RouterLink to="https://stripe.com/us/privacy" target="_blank">https://stripe.com/us/privacy</RouterLink></li>
                            <li><b className="underline">Social Media: </b> We may collect your personal information if you interact with our social media pages (i.e., Facebook, YouTube, etc.). We may collect embedded content from your social media profile, and we may receive personal information about you from such social networking app subject to the privacy policy of those social networking sites.</li>
                            <li><b className="underline">Feedback or Review: </b> We also may collect personal information if included in any feedback or comment provided by our users. Feedback and similar communications from our users allow us to measure areas of interest, improve the efficiency of our website and its services, provide users with important information, and build features and functionality that will enhance your use of our website or apps and its services. </li>
                            <li><b className="underline">Communications with us via Email, Live Chat, or Other ways: </b> When you communicate with us using our contact us form, support email, phone call, or live chat option or/with a phone call, we may collect personal information through such communications.</li>
                            <li><b className="underline">Other Information:</b> You may otherwise choose to provide us with other personally identifiable information about you and third parties that you may choose to transmit through the Services, including personally identifiable information contained within files, video or audio calls, or text messages.</li>
                        </ul>
                        <h4 className="underline">Information Collected via Technology:</h4>
                        <p>To make our website and its services more useful to you, our servers (which may be hosted by a third-party service provider) may collect information from you, including your browser type, operating system, Internet Protocol (IP) address, Geo-location, and/or a date/time stamp for your visit. When you use our website and its services, we may collect information about usage information, location information, log data, transaction information, cookies, and similar technologies, "clear gifs" or "web beacons." This automatically collected information may include your IP address or other device address or ID, web browser and/or device type, etc. We may use Google analytics feature and other third-party vendors to collect information anonymously and report website trends without identifying individual visitors. Google uses its cookies to track visitor interactions. Website owners can view a variety of reports about how visitors interact with their website so they can improve their website and how people find it.</p>
                        <p>Except as stated above, we do not request, solicit or intend to collect any other personally identifiable information, and you should not disclose any such information in connection with the Services. IF YOU SUBMIT ANY PERSONAL INFORMATION OF OR RELATING TO THIRD PARTIES TO US OR OUR THIRD-PARTY SERVICE PROVIDERS IN CONNECTION WITH THE SERVICES, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO DO SO.</p>
                        <h4 className="underline">PURPOSES OF COLLECTION AND USE OF YOUR PERSONAL INFORMATION</h4>
                        <p>Our third-party service providers and we may use your personal information to provide our services to you smoothly and to meet your satisfaction to use our website and its services and to address your needs and concerns. For the following purposes, our third-party service providers and we may collect and use your personal information, including but not limited to </p>
                        <ul>
                            <li>Personalize your experience using our website and its services.</li>
                            <li>To respond to your inquiries and fulfill your requests, such as sending newsletters to you, completing your commercial transactions, and operating the Services</li>
                            <li>to maintain your interest and account with us.</li>
                            <li>Analyze and enhance our communications and strategies.</li>
                            <li>Provide our services to you and your services to other users of the website.</li>
                            <li>Send you texts, emails, or other communications regarding general adherence information, service maintenance, updates, or changes to this privacy policy or any other relevant agreements.</li>
                            <li>To send to you administrative information, including information regarding the Services and changes to our terms, conditions, and policies,</li>
                            <li>create, manage, and control your account and dashboard information, and verify access rights to the platform and our services</li>
                            <li>respond to your inquiries, commercial requests, and comments, and provide customer support. </li>
                            <li>To allow us to pursue available remedies or limit the damages that we may sustain.</li>
                            <li>To protect the security and safety of our users and protect against and prevent fraud, unauthorized transactions, claims, and other liabilities, and manage risk exposure, including by identifying potential hackers and other unauthorized users. And, respond to claims of any violation of our rights or those of any third parties;</li>
                            <li>comply with all applicable legal requirements, industry standards, and our policies, including necessary obligation to comply with legal processes and law enforcement instructions and orders.</li>
                            <li>at our discretion under emergency circumstances, to notify emergency services or your family members, personal representative, or other individuals involved in the care of your location and condition;</li>
                            <li>enforce this privacy policy.</li>
                        </ul>
                        <p>We will not share any of your personally identifiable or transactional information with any person or entity other than as set out in this policy. We do not rent, sell, or share our user's personal information with any people, entity, or non-affiliated companies for marketing purposes (including direct marketing purposes) without your explicit permission.</p>
                        <h4>Disclosing or Sharing Your Personal Information:</h4>
                        <p>We have sole discretion to take all reasonable steps to secure the information against unauthorized access or disclosure. Subject to this privacy policy and in accordance with any applicable law, we may disclose the personal information we collect from you for the following circumstances and to the following third parties, whether they are located in your country or overseas.</p>
                        <p>We may share or disclose your information</p>
                        <ul>
                            <li>to our trusted third-party service providers that may provide services such as hosting of the Services, data analysis, IT services and infrastructure, customer service, email delivery, auditing, payment processing, and other similar services.(<i>Please note that we may use cloud service providers in connection with the hosting of the Services and the storage of Personal Information, and we may have limited or no opportunity or ability to impose contractual restrictions on these and other service providers. In the following circumstances and with your prior permission, we may also share your information</i>).</li>
                            <li>to our employees, contractors, and/or related entities.</li>
                            <li>to whom we transfer or may transfer our rights and duties</li>
                            <li>to any relevant government regulators or authority or law enforcement agency to comply with any laws or rules and regulations imposed by any governmental authority; and</li>
                            <li>to any other party, entity, or authority when we have believed in good faith that disclosing any information is necessary to protect our rights or interest, or property. </li>
                            <li>To any other party, entity, or authority, when we have believed in good faith that disclosing any information is appropriate in connection with efforts to investigate, prevent, or take other action regarding illegal activity, suspected fraud, or other wrongdoing; to protect and defend our  rights, property, or safety of our company, users, employees, or others; </li>
                            <li>at our discretion under emergency circumstances, to notify emergency services or your family members, personal representative, or other individuals involved in the care of your location and condition.</li>
                            <li>To protect and defend the legal rights, liabilities, interests, property, or safety of our company, users, employees, or others to protect and defend our rights, </li>
                            <li>to allow us to pursue available remedies or limit the damages that we may sustain</li>
                            <li>to whom you authorize us to disclose your personal information.</li>
                            <li>To comply with applicable law or cooperate with law enforcement;</li>
                        </ul>
                        <p>Your personal information may also be disclosed by you through messages (including files, video and audio calls, and text messages), chats, message boards, forums, public profile pages, user directories and blogs, and other functionality with which you are able to share or post information and materials. Please note that any information and materials that you post or disclose using such functionality will become public information and may be available for other users of the Services and the general public to record, access, use, alter, manipulate, or disclose to other individuals or social networking sites such as Facebook, YouTube, etc. We are not responsible for such recording, access, use, alteration, manipulation, or disclosure. We urge you to be very careful when deciding to disclose your Personal Information, or any other information, in connection with the Services.</p>
                        <p>We may also disclose or share your personal information in connection with a substantial corporate transaction, such as the sale of our business, a divestiture, merger, consolidation, or asset sale, or in the unlikely event of bankruptcy. In case of a change in ownership of our business through any viable means like a merger, an acquisition, a takeover, or any similar event, your personal information may also be transferred to the new owner. In such scenarios, we will post a notice or otherwise notify you and collect your consent, as may be required by law, before the information is transferred and becomes subject to a different privacy policy.</p>
                        <h4>COOKIES POLICY: </h4>
                        <p>Our website may use cookies to enhance user experience. Your web browser may place cookies on the hard drive of your device for record-keeping purposes and sometimes to track information/data about you. Cookies are small files and understood as IT data, in particular short text files, stored by a web browser or directly in the end devices of users, intended for the use of websites. Cookies usually contain the name of the website they come from, the storage time on the end device, and a unique number.</p>
                        <p>There have two basic types of cookies: Session Cookies and Persistent Cookies. Session files are temporary files that are stored on the user's end device until logging out, leaving the application, or turning off the software (web browser). Permanent files are stored in the user's end device for the time specified in the parameters of cookies or until they are manually deleted by the user. </p>
                        <p>By showing how and when visitors use the website, cookies help to identify how many unique users visit us and track user trends and patterns. It also prevents you from having to re-enter your preferences on certain areas of the website where you may have entered preference information before. We may also use web beacons (single-pixel graphic files, also known as "transparent GIFs") to access cookies and to count users who visit the website or open HTML-formatted email messages. Cookies can compile information about your browsing habits and can also enhance your browsing experience. We may use cookies for the following purposes:</p>
                        <ul>
                            <li>To understand browsing habits on the websites;</li>
                            <li>To understand the number of visitors to our website and its pages visited;</li>
                            <li>To ensure that we give you the best user experience; </li>
                            <li>To remember your preferences;</li>
                            <li>To help us understand your preferences based on previous or current site activity;</li>
                            <li>To help us compile aggregate data about site traffic and site interaction;</li>
                            <li>To enable our site to function properly;</li>
                        </ul>
                        <p>Personal data (if any) that we collect from you through cookies may be passed to our third-party services providers for managing and/or administering our website or for data hosting/storage/backup. Your use of our website constitutes consent by you to our use of cookies and the matters set out herein. You can instruct your browser, by editing its options, to stop accepting cookies or to prompt you before accepting a cookie from our website when you visit. Please note that by not accepting cookies, you might not be able to use all functions of the website.</p>
                        <p>If you turn cookies off, some of the features that make your website users' experience more efficient may not function properly. It won't affect the user's experience, which makes your website experience more efficient and may not function properly.</p>
                        <p>You may manage how your browser handles cookies and related technologies by adjusting its privacy and security settings. However, you can allow cookies from specific websites by making them trusted websites in your web browser. Find out how to manage cookies on popular browsers:</p>
                        <div className="browsers_list">
                            <p><RouterLink to="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">Google Chrome</RouterLink></p>
                            <p><RouterLink to="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank">Microsoft Edge</RouterLink></p>
                            <p><RouterLink to="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US" target="_blank">Mozilla Firefox</RouterLink></p>
                            <p><RouterLink to="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Microsoft Edge</RouterLink></p>
                            <p><RouterLink to="https://help.opera.com/en/latest/web-preferences/" target="_blank">Opera</RouterLink></p>
                            <p><RouterLink to="https://support.apple.com/en-gb/safari" target="_blank">Apple Safari</RouterLink></p>
                        </div>
                        <p><i>We have sole rights to provide an explanation of these cookies policy. If you have any questions regarding this cookies policy, please feel free to contact us at <RouterLink to="mailto:admin@forember.com ">admin@forember.com</RouterLink></i></p>
                        <h4>SOCIAL MEDIA PAGES/INTERACTIVE SERVICES</h4>
                        <p>If you interact with us by and through the interactive services or any social media pages, you should be aware that the personal information that you submit by and through such venues can be read, collected, and/or used by other users of these forums (depending on your privacy settings associated with the accounts you have with the hosts of such social media pages, if applicable), and could be used to send you unsolicited messages or otherwise to contact you without your consent or desire. We are not responsible for the personal information that you choose to submit in these forums. The Social Media Pages reside on the website that is operated independently from us, and we are not responsible for such social media sites, interfaces, or privacy or security practices. We encourage you to review the privacy policies and settings of the social media sites with which you interact to help you understand those social media site's privacy practices. If you have questions about the security and privacy settings of any social media sites that you use, please refer to their applicable privacy statements or policies.</p>
                        <h4>USER-GENERATED CONTENT</h4>
                        <p>You may share Personal Data with us when you submit user-generated content to our Services, including via forums, message boards, blogs, etc. on our Services. Any information you post or disclose on these Services will be publicly available and visible to other users of our Services and to the general public. We urge you to be cautious before deciding to disclose your Personal Information, or any other information, on our Services. If you provide feedback to us, we may use and disclose such feedback on our Services. If you have provided your consent to do so, we may post your first and last name along with your feedback on our Services. We will collect any information contained in such feedback and will treat the Personal Information in it in accordance with this policy.</p>
                        <h4>LEGAL BASIS TO PROCESS YOUR PERSONAL INFORMATION </h4>
                        <p>As a responsible organization, we need a lawful basis for collecting and/or processing your data. We generally rely on a number of grounds (reasons) for this processing within our business activities. We process your personal information in accordance with the provisions set out in the CCPA and the relevant applicable data protection laws and regulations. The legal bases for processing your personal information are:</p>
                        <ul>
                            <li><b className="underline">To comply with contractual obligations: </b> When you use our website or its services for any purpose, the purposes of processing your personal information are primarily determined by that use, and we will process your information so that we can provide your purpose for using our app and its services.</li>
                            <li><b className="underline">As a result of your consent. </b> When you have consented to the processing of your personal information by us for certain services through the app, you can withdraw this consent at any time by following the instructions provided in the subscription process or by contacting us at <RouterLink to="mailto:admin@forember.com ">admin@forember.com</RouterLink></li>
                            <li><b className="underline">There is a legitimate interest. </b> In certain situations, we may not need your consent to use your data as we will have a legitimate interest in doing so, but we must inform you of this. Such as 
                                <ul>
                                    <li>to analyze and optimize our website and its services;</li>
                                    <li>to guarantee IT security and safeguard our  IT operations;</li>
                                    <li>to prevent and investigate criminal acts.</li>
                                    <li>To protect our legal rights, liabilities, and interests</li>
                                </ul>
                            </li>
                        </ul>
                        <p>We will follow all applicable legal obligations to use your personal information for the legitimate interest. As with any other entity, we are subject to legal obligations and regulations. </p>
                        <h4>ADVERTISING AND ANALYTICS</h4>
                        <p>We may use third-party advertising and analytics platform, including but not limited to Google ads, Facebook Ads, Google Analytics, etc., to promote our website and its services. Please note that these companies may use information about your use of the Services to provide advertisements about goods and services that may be of interest to you. We do not share personal information that directly identifies you with third-party advertisers for their direct marketing purposes. We may use Google Analytics, which may use cookies. The information generated by the cookies about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. You can refuse the use of Google Analytics by clicking on opt-out. To learn more about how Google uses data, visit <RouterLink to="https://policies.google.com/privacy/partners" target="_blank">https://policies.google.com/privacy/partners</RouterLink>.to opt out from Google Analytics. Please visit <RouterLink to="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</RouterLink>.</p>
                        <h4>SECURITY OF YOUR PERSONAL INFORMATION</h4>
                        <p>We are committed to protecting or safeguarding the data we collect from you. We take all reasonable organizational, technical, and administrative measures and necessary steps to protect the information provided by you from unauthorized access. We may follow commonly accepted industry standards to protect the personal information submitted to us. We are using SSL CERTIFICATE, and other encrypts security systems to ensure and protects the confidentiality of your personally identifiable information. However, no method of transmission over the Internet or method of electronic storage is 100% safe and secure. Therefore, we are not in the actual position to guarantee the absolute security of your information. We may allow third parties to assist us regarding processing your personal information and adopt proper security measures to safeguard your information for ensuring security. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of any account you might have with us has been compromised), please immediately notify us of the problem by contacting us in accordance with the "Contacting Us" section below.</p>
                        <h4>CHILDREN PRIVACY POLICY </h4>
                        <p>We take children's privacy seriously. Our website and its services comply with the Children's Online Privacy Protection Act (COPPA) and the Children's online privacy protection law under the local territory of every user of our website and its services. We do not knowingly collect or solicit personal information from anyone under the age of thirteen (13), nor are such individuals permitted to use the Services; accordingly, we request that such individuals do not provide Personal Information in connection with the Services. If you are the parent or guardian of such an individual who has disclosed Personal Information to us in connection with the Services, please contact us in accordance with the "Contacting Us" section below, and we will exercise commercially reasonable efforts to remove such information from our databases and storage systems; we are not liable, however, for any consequences of such information, including any delay or failure to remove such information.</p>
                        <h4>GOVERNING LAW AND JURISDICTION</h4>
                        <p>This Privacy Policy has been prepared based on provisions of the applicable state and federal law of the United States, California Consumer Privacy Act (CCPA), Children's Online Privacy Protection Act (COPPA), California Online Privacy Protection Act of 2003 (CalOPPA), applicable International data protection law and other relevant law of the local territory of the user. Each user and we may submit to the exclusive jurisdiction of the local territory of the users. </p>
                        <h4>COMPLIANCE WITH LAWS AND REGULATIONS </h4>
                        <p>We may disclose your personal information according to comply with applicable law within or outside your country of residence, legal process, litigation requests, or requests from public and governmental authorities; We may also disclose your personal information if it is necessary in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of our terms of service or as otherwise required by law; We encrypt the transmission of information on pages where you provide payment information. However, no security or encryption method can be guaranteed to protect information from hackers or human error. In no event do we not sell, trade, rent, or otherwise transfer your personally identifiable information to any third parties except the above circumstances.	</p>
                        <h4>CHOICES OR RIGHTS YOU HAVE ABOUT YOUR INFORMATION</h4>
                        <p>You may, of course, decline to submit information to us, in which case you may not be able to use our platform and its services. You may also control the types of notifications and communications we send and limit the information shared within our platform or otherwise amend certain privacy settings. </p>
                        <p>You have certain rights under the applicable data privacy law, including the right to request a copy of the personal information we hold about you if you request it from us in writing. If you request to exercise any of such rights, please require us in writing. It may take up to 30 days to exercise this right. At the same time, for security reasons, we will verify your person to make sure that your data will reach the right person. You can exercise the following rights in accordance with this privacy policy.</p>
                        <p>We will comply with different data practices as necessary. According to CCPA, California Civil Code Section § 1798.83, and other applicable US Federal and states data privacy law, you have the following rights.</p>
                        <ul>
                            <li><b className="underline">Right to know: </b>You have the right to request to know what information we collect from you and how we have collected and used your Personal Information during the past 12 months.</li>
                            <li><b className="underline">Right to Access: </b> You have the right to request copies of your personal information from us;</li>
                            <li><b className="underline">Right to delete: </b>You have the right to request us to delete your personal information that has been collected in the past 12 months.</li>
                            <li><b className="underline">Right to Opt-Out: </b> You have the right to opt out of any use of your Personal Information as defined in the CCPA.</li>
                            <li><b className="underline">Right to Nondiscrimination: </b> You are entitled to exercise the rights described under this privacy policy free from any discrimination, which is prohibited under CCPA.</li>
                            <li><b className="underline">Right to correct: </b>You have the right to request us to have your personal information rectified if it is inaccurate or incomplete;</li>
                            <li><b className="underline">Right to withdraw: </b> You have the right to withdraw consent where you have previously given your consent to the processing of your personal information.</li>
                            <li><b className="underline">Right to restrict our use of your Information: </b>You have the right to restrict us from using your personal information or limit the way in which we can use it;</li>
                            <li><b className="underline">Right to data portability: </b>You have the right to request us to move, copy or transfer your personal information.</li>
                            <li><b className="underline">Right to object: </b> You have the right to object to our use of your personal information, including where we use it for our legitimate interests or where we use your personal information to carry out profiling to inform our market research and user demographics.</li>
                            <li><b className="underline">Lodge a complaint: </b>You have the right to bring a claim before the competent data protection authority.</li>
                        </ul>
                        <p>You may exercise your right to access, update, amend or delete your Personal Information that has been previously provided to us; you may do so by logging into your account within the Services, or you may contact us in accordance with the "Contacting Us" section below. We will try to comply with your request to update your Personal Information as soon as reasonably practicable. We are not responsible for changing information from the databases of third parties with whom we have already shared your Personal Information. Please note that we may ask you to verify your identity before responding to such requests. If you make a request, we will try our best to respond to you as soon as possible. </p>
                        <h4>DATA PROTECTION RIGHTS FOR CALIFORNIA RESIDENTS:</h4>
                        <p>In order to exercise any of your rights under the CCPA, and if you are a California resident, you can send us a written request through email or through registered post mail. We will disclose and deliver the required information free of charge within forty-five (45) days of receiving your verifiable request. The time period to provide the required information may be extended once by an additional forty-five (45) days when reasonably necessary and with prior notice. Also, Under California Civil Code Section 1798.83, if you live in California and your business relationship with us is mainly for personal, family, or household purposes, you may ask us about the information we release to other organizations for their marketing purposes. </p>
                        <h4>INTERNATIONAL DATA TRANSFER POLICY </h4>
                        <p>Please be aware that, Forember is registered and operated from the USA, but some of our service providers we may use for our website and its services may reside or be registered outside the United States. Personal information we collect from you may transfer to, stored, and processed in any country where our service provider is based or have facilities. By submitting any of your personal information to us, you acknowledge and agree that we may transfer your personal information outside the United States, and you consent to such transfer. We do not represent or warrant that our website or any part thereof is appropriate or available for use in any particular geographic location. If you choose to access our website and use its services, you can do so on your own initiative and at your own risk and are responsible for complying with all local laws, rules, and regulations applicable in your jurisdiction. If you are using our website and its services outside of the USA, please be aware that your information will be transferred to, stored, and processed in the USA, where our servers are located, and our central database is operated. By using our website and its services, you consent to your information being transferred to our facilities and to the facilities of those third parties with whom we share it as described in this privacy policy. </p>
                        <h4>AUTORESPONDERS AND MARKETING OPT-OUTS</h4>
                        <p>We give you many choices regarding our use and disclosure of your Personal Information for marketing purposes. We may use the auto responder's tool to communicate with you by email. You may opt-out from receiving marketing-related emails from us based upon your Personal Information: If you no longer want to receive marketing-related emails from us on a going-forward basis based upon Personal Information collected pursuant to this Privacy Policy, you may opt-out of receiving such marketing-related emails by clicking the "unsubscribe" link that is included at the end of such marketing-related emails or following the instructions in those messages. We will try to comply with your request(s) as soon as reasonably practicable. Please note that if you opt out as described above, we will not be able to remove your Personal Information from the databases of third parties with which we have already shared such information (i.e., to which we have already provided such information as of the date that we implement your opt-out request). Please also note that if you do opt out of receiving marketing-related emails from us, we may still send administrative messages to you; you cannot opt out of receiving administrative messages. If you have difficulties opting out, you may contact us at <RouterLink to="mailto:admin@forember.com ">admin@forember.com</RouterLink></p>
                        <h4>RETENTION PERIOD/ DURATION OF HOLDING YOUR PERSONAL INFORMATION</h4>
                        <p>We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or allowed by law or until you request us to delete or erase it. Even if we delete your Personal Information, it may persist on backup or archival media for legal, tax, or regulatory purposes. If we have asked for your permission to process your personal information and we have no other lawful grounds to continue with that processing, and you withdraw your permission, we will delete your personal information.</p>
                        <h4>YOUR CONSENT:</h4>
                        <p>By using our website and its services, you provide your consent to us to collect process and transfer (including international transfer) your personal information in accordance with this privacy policy. Wherever possible, we will attempt to obtain your explicit consent to collect and process your information. If you have given us explicit permission to do so, we may, from time to time, provide your name and contact information to selected partners whom we believe may provide services or Services you would find useful. Sometimes you may give implicit consent, such as when you send us a message through our support or by email to which you would reasonably expect us to reply. Without your consent to use your personal information for a specific purpose, we do not use your information in any way that would identify you personally. We may continue to use, disclose or process your information in accordance with this Privacy Policy until you withdraw your consent. You may withdraw your consent at any time by contacting us as stated below. </p>
                        <h4>DO NOT TRACK (DNT) PRIVACY PRACTICE:</h4>
                        <p>Our website and its services do not respond to “Do Not Track” signals. We don't track you personally, and we never sell your personal information. Only we may track how users use our website in general so that we can make it better. We are monitoring traffic, usage activity, and website performance, and we use general analytic tools so that we can improve your experience. </p>
                        <h4>EXTERNAL LINKS POLICY</h4>
                        <p>Our website and its services may contain hypertext links to other websites or links and provide access to products and services offered by third parties; we don't control their privacy policies. When you access another website or purchase any third-party products or services through such external website and use of any personal information you provide to them will be governed by the privacy policy of the operator of the website you are visiting or the provider of such products or services.</p>
                        <h4>CHANGES OR MODIFICATION </h4>
                        <p>We have sole discretion to modify or update or amend this privacy policy at any time without any prior notice. Changes or modifications will take effect immediately upon posting on our website. If we make any material changes to this policy, we will notify you here that it has been updated so that you can be aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications. </p>
                        <h4>YOUR ACCEPTANCE OF THESE POLICIES</h4>
                        <p>By using our website and its services, you hereby imply your acceptance of this privacy policy. If you do not agree to this privacy policy, please do not use our website and its services. Your continued use of our website and its services will be deemed your acceptance of those changes.</p>
                        <h4>CONTACT US</h4>
                        <p>We have sole jurisdiction to provide an explanation of this privacy policy. If you have any questions about this policy or would like to access or modify your personally identifiable information, please contact us by using the contact us page or directly email us at <RouterLink to="mailto:admin@forember.com ">admin@forember.com</RouterLink></p>
                        <h4>FYI: Please note that </h4>
                        <ul>
                            <li>This privacy policy was last updated on March 2023.</li>
                            <li>We reserve the right to change this privacy policy at any time. Modifications to this privacy policy can be made without prior notification.</li>
                            <li>Continued use of our website signifies your acceptance of any changes to this privacy policy.</li>
                        </ul>
                    </div>
                </div>
            </>
        )
    }
};

export default PrivacyPolicy;
