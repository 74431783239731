import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { List, ListItem, Button } from "@mui/material";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";

const HeaderLinks = class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }
    }
    getDesktopMenu(){
        const { authorized } = this.props.state;
        
        let menuItems = [];
        if(!authorized){
            menuItems.push(<RouterLink to="/about-us" onClick={() => this.onLinkClick("/about-us")} className={"navLink"}>
                About Us
            </RouterLink>);
            menuItems.push(<RouterLink to="/login" onClick={() => this.onLinkClick("/login")} className={"navButton"}>
                <Button size={"lg"} className={"button radius-10"}>Sign In</Button>
            </RouterLink>);
            menuItems.push(<RouterLink to="/signup" onClick={() => this.onLinkClick("/signup")} className={"navButton"}>
                <Button size={"lg"} className={"button radius-10"}>Create Account</Button>
            </RouterLink>);
        }else{
            menuItems.push(<RouterLink to="/home" onClick={() => this.onLinkClick("/home")} className={"navLink"}>
                Dashboard
            </RouterLink>);
            if(this.props.desktop === false){
                menuItems.push(<RouterLink to="/about-us" onClick={() => this.onLinkClick("/about-us")} className={"navLink"}>
                    About Us
                </RouterLink>);
            }
        }
        return menuItems;
    }
    onLinkClick(href){
        this.props.history.push(href);
        if(href === '/home'){
            this.props.history.home = true;
        }else{
            this.props.history.home = false;
        }
    }
    render() {
        if(this.props.desktop){
            return (
                <List className={"list testing"}>
                    {
                        this.getDesktopMenu().map((menuItem, key) => {
                            return (
                                <ListItem className={"listItem"} key={key}>
                                    {menuItem}
                                </ListItem>
                            )
                        })
                    }
                </List>
            );
        }
        return (
            <div className={"collapse"}>
                <List className={"list testing1"}>
                    {
                        this.getDesktopMenu().map((menuItem, key) => {
                            return (
                                <ListItem className={"listItem"} key={key}>
                                    {menuItem}
                                </ListItem>
                            )
                        })
                    }
                </List>
            </div>
        );
    }
};

HeaderLinks.defaultProps = {
  onClose: function(){ }
};

HeaderLinks.propTypes = {
  onClose: PropTypes.func,
};

export default connect(mapStateToProps)(HeaderLinks);