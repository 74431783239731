import React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Button, DialogActions } from "@mui/material";
import { Close } from "@mui/icons-material";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/reducers/rootReducer";
import Api from "../../../assets/js/utils/Api";
import axios from "axios";
import LoaderComponent from '../Loader';

import "../../../assets/scss/emailModalStyle.scss";

class CreatorEmailModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            hug: props.hug,
            emailSent: false,
            thanksValue: 'wonderful',
            otherText: '',
            otherTextLimit: 70,
            otherTextLength: 0,
            thanksOptions: this.getThanksOptions(),
            validation: {
                otherText: '',
                isValid: false
            },
        }
    }
    componentDidMount(){
    }
    getThanksOptions(){
        return [
            {
                key: 'wonderful',
                value: 'Thank you for this wonderful gift of love.'
            },
            {
                key: 'thoughtful',
                value: 'You are so thoughtful and I will cherish this hug forever.'
            },
            {
                key: 'appreciate',
                value: 'I appreciate you taking the time to show that you care.'
            },
            {
                key: 'other',
                value: 'Other'
            },
        ];
    }
    onLinkClick(href){
        this.props.history.push(href);
    }    
    onSendEmail(e){
        const { hug } = this.state;

        this.sendCreatorEmail(hug);
    }
    getEmailText(){
        const { thanksOptions, thanksValue, otherText } = this.state;

        if(thanksValue === 'other'){
            return otherText;
        }else{
            return thanksOptions.find(item => item.key === thanksValue)?.value;
        }
    }
    validateForm(){
        const { thanksValue, otherText } = this.state;

        let validation = {
            otherText: "success",
            isValid: true
        };

        if(thanksValue === 'other' && otherText.trim().length <= 0){
            validation.otherText = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    sendCreatorEmail(hug){
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        let requestData = {
            id: hug.id,
            emailText: this.getEmailText()
        };

        this.setState({
            sending: true
        })

        const source = axios.CancelToken.source();
        Api.sendCreatorEmail(requestData, source).then(data => {
            this.setState({
                response: data,
                sending: false,
                emailSent: true
            });
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                sending: false,
            });
        });
    }
    handleChange(e, name){
        const { otherTextLimit } = this.state;
        let value = e.target.value;
        if(value.length > otherTextLimit){
            value = value.slice(0, otherTextLimit);
        }

        this.setState({
            [name]: value,
            otherTextLength: value.length
        });
    }
    handleCheckbox(e, name, value){
        this.setState({
            [name]: value
        });
    }
    renderThanksOption(option){
        const { thanksValue, otherText, otherTextLength, otherTextLimit, validation } = this.state;
        return( 
            <div>
                <div className={"sd-email-option"}>
                    <div className="sd-email-fields-radio">
                        <input 
                            type="radio" 
                            id={"thanksValue-"+option.key} 
                            name="thanksValue" 
                            onChange={(e) => this.handleCheckbox(e, 'thanksValue', option.key)}
                            checked={thanksValue === option.key}
                        />
                        <label htmlFor={"thanksValue-"+option.key}>{option.value}</label>
                    </div>
                    <div className="sd-email-value">
                        <label htmlFor={"thanksValue-"+option.key}>{option.value}</label>
                    </div>
                </div>
                {
                    option.key === 'other' && thanksValue === 'other' ? 
                        <div className="sd-other-text">
                            <textarea
                                className={validation.otherText === "error" ? 'error' : ''}
                                placeholder=""
                                onChange={(e) => this.handleChange(e,'otherText')}
                                value={otherText}
                            />
                            <p>{ otherTextLength }/{ otherTextLimit }</p>
                        </div>
                    :
                        <></>
                }
            </div>
        );
    }
    render() {
        const { sending, emailSent, thanksOptions } = this.state;

        return (
            <>
                <Dialog
                    maxWidth="lg"
                    fullWidth={true}
                    open={this.props.open}
                    className="sd-email-modal"
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose(emailSent)}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                        {
                            emailSent === false ?
                                <h5 className={"modal-title"}>Choose a response to express your gratitude.</h5>
                            :
                                <></>
                        }
                    </DialogTitle>
                    <DialogContent>
                        <div className="sd-email-modal-content">
                            {
                                emailSent ?
                                    <p>Your email has been sent!</p>
                                :
                                    <div className="sd-thanks-radioboxes-content">
                                        {
                                            thanksOptions.map(option => {
                                                return this.renderThanksOption(option);
                                            })
                                        }
                                    </div>
                            }
                        </div>
                    </DialogContent>
                    <DialogActions
                        className={"modal-footer"}
                    >
                        {
                            sending ?
                                <LoaderComponent />
                            : emailSent ?
                                <Button className={"button radius-8"} onClick={() => this.props.onClose(emailSent)}>Close</Button>
                            :
                                <Button className={"button radius-8"} onClick={(e) => this.onSendEmail(e)}>Send</Button>
                        }
                    </DialogActions> 
                </Dialog>
            </>
        )
    }
}
export default connect(mapStateToProps)(CreatorEmailModal);