import React, { Component } from "react";
import AuthApi from '../../assets/js/utils/Auth';
import { Button } from "@mui/material";
import { PlayArrow } from "@mui/icons-material";
import videoImg from "../../assets/img/home_video_img.png";
import instructionsIcon from "../../assets/img/instructions_icon.png";
import dataCollectionIcon from "../../assets/img/data_collection_icon.png";
import bigDataIcon from "../../assets/img/big_data_icon.png";
import infiniteIcon from "../../assets/img/infinite_icon.png";
import calendarIcon from "../../assets/img/calendar_icon.png";
import remindersIcon from "../../assets/img/reminders_icon.png";
import freeIcon from "../../assets/img/free_icon.png";
import VideoModal from "../components/Modal/VideoModal";
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/reducers/rootReducer";
import { Link as RouterLink } from "react-router-dom";
import hugVideo from "../../assets/video/grandma_ruth_hug_for_forember_site.mp4";
import introVideo from "../../assets/video/explainer_video_forember.mp4";

import "../../assets/scss/homeStyle.scss";

class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            hugVideoModal: false,
            introVideoModal: false
        };
        this.loading = this.props.loading;
        const hasAccessToken = AuthApi.hasAccessToken();
        if(hasAccessToken === false){
            this.loading = false;
        }
    }
    handleVideoModal(name, status){
        this.setState({
            [name]: status
        });
    }
    onLinkClick(href){
        this.props.history.push(href);
    }
    render() {
        const { authorized } = this.props.state;
        if(authorized){
            // this.props.history.push("/home");
        }

        return (
            <>
                <div className="container">
                    <div className='sd-home-content-video-section'>
                        <div className='sd-home-content-section'>
                            <h1>Spread love with forever hugs.</h1>
                            <p>A forever hug is a personalized, intimate connnection created for a loved one that will last forever</p>
                            <div className='sd-video-btn'>
                                <RouterLink to="/create-hug" onClick={() => this.onLinkClick("/create-hug")} className={"buttonlink"}>
                                    <Button className={"button radius-10"}>
                                        Create your free hug
                                    </Button>
                                </RouterLink>
                                <p onClick={()=> this.handleVideoModal('introVideoModal',true)}><PlayArrow />Video introduction (1:06)</p>
                            </div>
                        </div>
                        <div className="sd-home-video-hug-section">
                            <div className='sd-home-video-section'>
                                <div className="play-icon" onClick={()=> this.handleVideoModal('hugVideoModal',true)}>
                                    <div className="play-icon-circle">
                                        <PlayArrow />
                                    </div>
                                </div>
                                <img src={videoImg} alt="video_img" />
                            </div>
                            <p>Example Forever Hug (1:07)</p>
                        </div>
                    </div>
                    <div className='sd-home-mouse-section'>
                        <div className="mouse-icon-circle">
                            <div className="mouse-icon"></div>
                        </div>
                    </div>
                </div>
                <div className="sd-home-features-section">
                    <div className="container">
                        <h1>Features</h1>
                        <div className="sd-features-lists">
                            <div className="sd-feature-content">
                                <img src={instructionsIcon} alt="instructions_icon" />
                                <h4>Step by Step Guidance</h4>
                                <p>Create your forever hug in 4 easy steps in less than 10 minutes.</p>
                            </div>
                            <div className="sd-feature-content">
                                <img src={dataCollectionIcon} alt="instructions_icon" />
                                <h4>Hug Collections</h4>
                                <p>Hugs that you create and receive are all stored in one place.</p>
                            </div>
                            <div className="sd-feature-content">
                                <img src={bigDataIcon} alt="instructions_icon" />
                                <h4>Secure Storage</h4>
                                <p>We use the highest security standards in the industry.  </p>
                            </div>
                            <div className="sd-feature-content">
                                <img src={infiniteIcon} alt="instructions_icon" />
                                <h4>Permanent</h4>
                                <p>Store your hugs permanently on forember’s site to keep forever.</p>
                            </div>
                            <div className="sd-feature-content">
                                <img src={calendarIcon} alt="instructions_icon" />
                                <h4>Flexible Delivery <span>(Coming Soon)</span></h4>
                                <p>Decide when you want your forever hug to be delivered to your loved ones. </p>
                            </div>
                            <div className="sd-feature-content">
                                <img src={remindersIcon} alt="instructions_icon" />
                                <h4>Reminders</h4>
                                <p>Get forever hugs sent to you on special dates (birthdays, anniversaries, holidays) to remember your loved one.</p>
                            </div>
                            <div className="sd-feature-content">
                                <img src={freeIcon} alt="instructions_icon" />
                                <h4>Free</h4>
                                <p className="full-widht">Forever Hugs are always free to create and download for yourself. <span>Coming Soon:</span> There will be an option to sign up for a paid service if you choose to use other Forember features.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.hugVideoModal ?
                        <VideoModal 
                            open={this.state.hugVideoModal}
                            handleModal = {(status) => this.handleVideoModal('hugVideoModal', status)}
                            videoSrc={hugVideo}
                        />
                    :
                        <></>
                }
                {
                    this.state.introVideoModal ?
                        <VideoModal 
                            open={this.state.introVideoModal}
                            handleModal = {(status) => this.handleVideoModal('introVideoModal', status)}
                            videoSrc={introVideo}
                        />
                    :
                        <></>
                }
            </>
        )
    }
};
export default connect(mapStateToProps)(Home);
