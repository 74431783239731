import React, { Component } from "react";
import { Button } from "@mui/material";
import LoaderComponent from '../components/Loader';
import Api from "../../assets/js/utils/Api";
import axios from "axios";
import { Error } from "@mui/icons-material";
import RecaptchaV2 from "../components/RecaptchaV2";
import SuccessModal from '../components/Modal/SuccessModal';

import "../../assets/scss/contactUsStyle.scss";

class ContactUs extends Component {
    constructor(props){
        super(props);

        this.state = {
            full_name: "",
            email: "",
            message: "",
            saving: false,
            showError: true, 
            errorMessage: null,
            showRecaptcha: true,
            recaptchaToken: "",
            resetCaptcha: false,
            validation: {
                full_name: '',
                email: '',
                message: '',
                recaptcha: '',
                isValid: false
            },
            successModal: false
        };
    }
    componentDidMount(){
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    handleChange(e, name){
        const value = e.currentTarget.value;
        this.setState({
            [name]: value
        });
    }
    isValid(){
        const { full_name, email, message, recaptchaToken } = this.state;

        let validation = {
            full_name: "success",
            email: "success",
            message: "success",
            recaptcha: "success",
            isValid: true
        };
        if(full_name.length <= 2){
            validation.full_name = "error";
            validation.isValid = false;
        }
        var emailRegex = /\S+@\S+\.\S+/;
        if(email.length <= 0 || !emailRegex.test(email)){
            validation.email = "error";
            validation.isValid = false;
        }
        if(message.length <= 0){
            validation.message = "error";
            validation.isValid = false;
        }
        if(recaptchaToken.length <= 0){
            validation.recaptcha = "error";
            validation.isValid = false;
        }

        this.setState({validation: validation});
        return validation.isValid;
    }
    onSubmit(){
        if(!this.isValid()){
            return;
        }
        const { full_name, email, message } = this.state;
        const requestData = {
            full_name: full_name,
            email: email,
            message: message
        };
        this.setState({
            saving: true
        })
        const source = axios.CancelToken.source();
        Api.sendContactRequest(requestData, source).then(data => {
            this.setState({
                full_name: "",
                email: "",
                message: "",
                recaptchaToken: "",
                resetCaptcha: true,
                saving: false
            });

            this.onSuccessModal(true);
        }).catch(err => {
            this.setState({
                showError: true, 
                errorMessage: err.message,
                saving: false,
            })
        });
    }
    onRecaptcha(token){
        this.setState({
            showRecaptcha: false,
            recaptchaToken: token
        });
    }
    resetCaptchaStatus(){
        this.setState({
            resetCaptcha: false
        })
    }
    onSuccessModal(status = false){
        this.setState({
            successModal: status
        })
    }
    onSuccessButton(){
        this.props.history.push("/home");
    }
    render() {
        const { full_name, email, message, saving, validation, showRecaptcha, resetCaptcha, successModal } = this.state;
        return (
            <div className="container">
                <div className='sd-contact-us-content'>
                    <h2>How can we help?</h2>
                    <div className='sd-contact-us-form-section'>
                        <div className="two-inputs">
                            <div className="element-container">
                                <input 
                                    className={validation.full_name === "error" ? 'error' : ''}
                                    type="text" 
                                    value={full_name} 
                                    name="full_name" 
                                    onChange={(e) => this.handleChange(e, "full_name")} 
                                    placeholder="Full Name" 
                                />
                                {
                                    validation.full_name === "error" ?
                                        <Error />
                                    :
                                        <></>
                                }
                            </div>
                            <div className="element-container">
                                <input 
                                    className={validation.email === "error" ? 'error' : ''}
                                    type="email" 
                                    value={email} 
                                    name="email" 
                                    onChange={(e) => this.handleChange(e, "email")} 
                                    placeholder="Email" 
                                />
                                {
                                    validation.email === "error" ?
                                        <Error />
                                    :
                                        <></>
                                }
                            </div>
                        </div>
                        <div className="element-container">
                            <textarea 
                                className={validation.message === "error" ? 'error' : ''}
                                name="message" 
                                value={message} 
                                onChange={(e) => this.handleChange(e, "message")} 
                                placeholder="Message" 
                            />
                            {
                                validation.message === "error" ?
                                    <Error />
                                :
                                    <></>
                            }
                        </div>
                        <div className={"recaptch "+(validation.recaptcha === "error" ? 'error' : '')}>
                            <RecaptchaV2 resetCaptcha={resetCaptcha} onChange={(response) => this.onRecaptcha(response)} resetCaptchaStatus={() => this.resetCaptchaStatus()} show={showRecaptcha} />
                        </div>
                        <Button onClick={() => this.onSubmit()} size={"lg"} className={"button radius-30"}>
                            {
                                saving ?
                                    <LoaderComponent />
                                :
                                    "Send"
                            }
                        </Button>
                    </div>
                </div>
                {
                    successModal ?
                        <SuccessModal
                            open={successModal}
                            title="Thank you. Your message has been sent."
                            buttonText="Go To Dashboard"
                            onClose={() => this.onSuccessModal(false)}
                            onButtonAction={() => this.onSuccessButton()}
                        />
                    :
                        <></>
                }
            </div>
        )
    }
};

export default ContactUs;
