import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

import "../../../assets/scss/uploadVideoModalStyle.scss";

class UploadVideoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        }

        this.hiddenFile = React.createRef();
    }
    onFocus = e => {
        this.hiddenFile.current.click(e);
    }
    onFileSelect(e){
        const files = e.currentTarget.files;
        let filesArray = [];
        for(var i = 0; i < files.length; i++){
            filesArray.push(files[0]);
        }
        this.setState({
            files: filesArray,
        });
    }
    renderFile(item, key){        
        return <li key={key}>
            <p className="file-name">{item.name}</p>
        </li>;
    }
    render() {
        const { files } = this.state;
        let fileName = '';
        if(files.length > 0){
            fileName = files[0].name;
        }

        return (
            <>
                <Dialog
                    className="sd-upload-video-modal"
                    open={this.props.open}
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                        <h5 className={"modal-title"}>Choose Video</h5>
                    </DialogTitle>
                    <DialogContent>
                        <div className="sd-upload-video-model-content">
                            <div className="sd-upload-video-file-input">
                                <input
                                    type="file"
                                    className={"input-File"}
                                    accept="video/*"
                                    ref={this.hiddenFile}
                                    onChange={(e) => this.onFileSelect(e)}
                                    value=''
                                />
                                <input type={"text"} value={fileName} onClick={() => this.onFocus()} />
                                <Button className={"button radius-8"} onClick={this.onFocus}>Choose file</Button>
                            </div>
                            <div className="sd-upload-video-files-list">
                                {
                                    files.length > 0 ?
                                        // <ul className={"files-list"}>
                                        //     {
                                        //         files.map((item, key) => (
                                        //             this.renderFile(item, key)
                                        //         ))
                                        //     }
                                        // </ul>
                                        null
                                    :
                                        <p>No File Chosen.</p>
                                }
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions
                        className={"modal-footer"}
                    >
                        <Button className="button radius-8" onClick={() => this.props.onClose()}>Cancel</Button>
                        <Button className="button radius-8" disabled={files.length === 0} onClick={() => this.props.onSuccess(files)}>Upload</Button>
                    </DialogActions> 
                </Dialog>
            </>
        )
    }
}
export default UploadVideoModal;