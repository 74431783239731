import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton, Button, DialogActions } from "@mui/material";
import { Close, CloudUpload, KeyboardVoice, PhotoSizeSelectActual, HighlightOff } from "@mui/icons-material";
import DragNDrop from "../Drag/Drop";
import PropTypes from "prop-types";

import "../../../assets/scss/uploadFileModalStyle.scss";

class UploadFileModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            files: []
        };

        this.fileUploadRef = React.createRef();
        this.onFileDelete = this.onFileDelete.bind(this);
    }
    onDrop(filesArray){
        const { limit, fileType } = this.props;
        let files = this.state.files;
        filesArray.map((file) => {
            if(file.file === null){
                return null;
            }
            if(file.type === "file" && file.file.type.indexOf(fileType) !== -1){
                files.push(file.file);
            }
            return null;
        });
        if(limit !== null){
            files.splice(limit);
        }
        this.setState({
            files: files,
            uploadFileModal: true
        });
    }
    onFileSelect(e){
        const { limit, fileType } = this.props;
        const files = e.currentTarget.files;
        let filesArray = this.state.files;
        for(var i = 0; i < files.length; i++){
            if(files[i].type.indexOf(fileType) !== -1){
                filesArray.push(files[i]);
            }
        }
        if(limit !== null){
            filesArray.splice(limit);
        }
        this.setState({
            files: filesArray,
        });
    }
    onFileDelete(key){
        let filesArray = this.state.files;
        filesArray.splice(key, 1);

        this.setState({
            files: filesArray
        });
    }
    render() {
        const { files } = this.state;
        const { fileType } = this.props;

        return (
            <>
                <Dialog
                    className="sd-upload-audio-modal"
                    open={this.props.open}
                >
                    <DialogTitle
                        className={"modal-header"}
                    >
                        <IconButton
                            className={"modal-close-button"}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            <Close className={"modal-Close"} />
                        </IconButton>
                        <h5 className={"modal-title"}>{ this.props.title }</h5>
                    </DialogTitle>
                    <DialogContent>
                        <DragNDrop onDrop={(files) => this.onDrop(files)}>
                            <div className="sd-upload-audio-content" onClick={() => this.fileUploadRef.current.click()}>
                                <CloudUpload />
                                <p>{ this.props.uploadBtnText }</p>
                                <input type="file" onChange={(e) => this.onFileSelect(e)} multiple ref={this.fileUploadRef} accept={this.props.accept} style={{display: "none"}} />
                            </div>
                        </DragNDrop>
                        <div className="sd-upload-files-list">
                            <ul>
                                {
                                    files.map((file, key) => {
                                        return (
                                            <li key={key}>
                                                <div className="file-name-icon">
                                                    {
                                                        fileType === 'audio' ?
                                                            <KeyboardVoice />
                                                        :
                                                            <PhotoSizeSelectActual />
                                                    }
                                                    <p className="file-name">{file.name}</p>
                                                </div>
                                                <div className="remove-file" onClick={() => this.onFileDelete(key)}>
                                                    <HighlightOff />
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </DialogContent>
                    <DialogActions
                        className={"modal-footer"}
                    >
                        <Button className="button radius-8" onClick={() => this.props.onClose()}>Cancel</Button>
                        <Button className="button radius-8" disabled={files.length === 0}  onClick={() => this.props.onSuccess(files)}>Upload</Button>
                    </DialogActions> 
                </Dialog>
            </>
        )
    }
}

UploadFileModal.defaultProps = {
    accept: "*",
    limit: null,
    fileType: "audio"
}
UploadFileModal.propTypes = {
    accept: PropTypes.string
}

export default UploadFileModal;